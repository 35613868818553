import { Controller } from "@hotwired/stimulus";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default class extends Controller {
  static targets = ["canvas"];
  static values = { params: Object };

  connect() {
    const element = this.canvasTarget;
    const chartValues = this.paramsValue;

    this.chart = new Chart(element.getContext("2d"), {
      type: "bar",
      data: {
        labels: chartValues.dates,
        datasets: [
          {
            label: "走行(万km)",
            type: "line",
            borderColor: "rgba(159, 164, 169, 1)",
            borderWidth: 2,
            backgroundColor: "rgba(159, 164, 169, 1)",
            pointBackgroundColor: "rgba(159, 164, 169, 1)",
            pointBorderColor: "rgba(159, 164, 169, 1)",
            radius: 3,
            data: chartValues.odometers,
            tension: 0.1,
            yAxisID: "rAxis",
          },
          {
            label: "価格(万円)",
            data: chartValues.prices,
            backgroundColor: [
              "rgba(40, 176, 218, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
              "rgba(42, 51, 57, 1)",
            ],
            borderColor: "rgba(220, 220, 220, 1)",
            fill: true,
          },
        ],
      },
      options: {
        animation: false,
        scales: {
          x: {
            reverse: true,
            grid: {
              display: false,
              borderColor: "rgb(42, 51, 57)",
              borderWidth: "1",
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 4,
              maxRotation: 0,
              padding: 0,
              font: {
                size: "10",
                weight: "bold",
              },
            },
          },
          y: {
            type: "linear",
            grid: {
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              count: 6,
              padding: 8,
              font: {
                size: "10",
                weight: "bold",
              },
              callback: function (val) {
                return val + "万";
              },
            },
          },
          rAxis: {
            position: "right",
            grid: {
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              count: 6,
              padding: 8,
              color: "rgb(159, 164, 169)",
              font: {
                size: "10",
                weight: "bold",
              },
              callback: function (val) {
                return val.toFixed(2) + "万";
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                if (context.dataset.yAxisID) {
                  return context.raw + "万km";
                } else {
                  return context.raw + "万円";
                }
              },
            },
          },
        },
      },
    });
  }

  disconnect() {
    this.chart.destroy();
    this.chart = undefined;
  }
}
