/**
 * jquery のアニメーションの設定を考慮しつつ、待つ。
 * @param {number} ms 待ち時間
 * @returns {Promise<void>}
 */
export function delay(ms) {
  return new Promise((resolve, _reject) => {
    if ($.fx.off) {
      // jquery のアニメーションがオフ (Capybara.disable_animation = true) の場合は、
      // 待たずにすぐ返す。
      resolve();
    } else {
      setTimeout(resolve, ms);
    }
  });
}
