import { Controller } from "@hotwired/stimulus";

/**
 * Enter押下時にsubmit動作をしないようにする
 *
 * 使用例:
 * ```
 * <form data-controller="disable-submit-on-enter">
 * ```
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.#disable_submit_on_enter);
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.#disable_submit_on_enter);
  }

  /**
   * Enterでsubmitしないようにする
   * @param {KeyboardEvent} e
   */
  #disable_submit_on_enter(e) {
    if (e.key == "Enter" || e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }
}
