import { Controller } from "@hotwired/stimulus";

/** 免許証画像を両面まとめて削除する。
 * drivers-license-image
 * app/views/secret_profiles/_form.html.erb 用。
 */
export default class extends Controller {
  static outlets = ["image-resize-preview"];
  static targets = ["expirationYear"];

  delete() {
    this.imageResizePreviewOutlets.forEach((controller) => {
      controller.delete();
    });

    // 有効期限もリセットする。
    this.expirationYearTarget.value = "";
  }
}
