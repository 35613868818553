import { Controller } from "@hotwired/stimulus";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default class extends Controller {
  static targets = ["canvas"];
  static values = { params: Object };

  connect() {
    const element = this.canvasTarget;
    const graphPrices = this.paramsValue;

    this.chart = new Chart(element.getContext("2d"), {
      type: "bar",
      data: {
        labels: graphPrices.labels,
        datasets: [
          {
            data: graphPrices.prices,
            backgroundColor: [
              "rgb(40, 176, 218)",
              "rgba(42, 51, 57)",
              "rgba(42, 51, 57)",
            ],
          },
        ],
      },
      options: {
        barPercentage: 0.7,
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: "rgb(42, 51, 57)",
              borderWidth: "1",
            },
            ticks: {
              color: "rgb(42, 51, 57)",
              padding: 0,
              font: {
                size: "10",
                weight: "bold",
              },
              callback: function (val) {
                return this.getLabelForValue(val)[1];
              },
            },
          },
          y: {
            suggestedMax: graphPrices.yAxisMax,
            grid: {
              drawBorder: false,
            },
            ticks: {
              count: 6,
              color: "rgb(42, 51, 57)",
              font: {
                size: "10",
                weight: "bold",
              },
              callback: function (val) {
                return val + "万";
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            displayColors: false,
          },
        },
      },
      plugins: [
        this.#residualValueChartTooltip(),
        this.#residualValueChartDataLabels(),
      ],
    });
  }

  disconnect() {
    this.chart.destroy();
    this.chart = undefined;
  }

  #residualValueChartTooltip() {
    return {
      afterDraw(chart) {
        const labels = chart.data.labels;
        const { ctx } = chart;
        ctx.save();

        chart.data.datasets.forEach((dataset, i) => {
          chart.getDatasetMeta(i).data.forEach((point, j) => {
            if (j === 0) return;
            const { x } = point.tooltipPosition(true);

            // MEMO: Tooltipを独自で実装して、常に表示させる
            // params
            const h = 20;
            const y = chart.chartArea.bottom;
            const text = labels[j][0] || "";
            ctx.font = "bold 10px Arial";
            const tw = ctx.measureText(text).width;
            const ts = 7;
            const px = 20;
            const w = tw + px;

            // box
            ctx.fillStyle = "rgba(90, 90, 90, 1)";
            ctx.fillRect(x - w / 2, y - h - ts, w, h); // x, y, w, h

            // triangle
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x - ts, y - ts);
            ctx.lineTo(x + ts, y - ts);
            ctx.fill();
            ctx.restore();

            // text: tooltip
            ctx.font = "bold 10px Arial";
            ctx.fillStyle = "white";
            ctx.fillText(text, x - tw / 2, y - 13);
            ctx.restore();
          });
        });
      },
    };
  }

  #residualValueChartDataLabels() {
    return {
      afterDraw(chart) {
        const { ctx } = chart;
        ctx.save();

        chart.data.datasets.forEach((dataset, i) => {
          chart.getDatasetMeta(i).data.forEach((point, j) => {
            const { x: xs, y: ys } = point.tooltipPosition(false);

            const colors = dataset.backgroundColor;
            const prices = dataset.data.slice(0, 3);
            const priceText = `${Math.floor(prices[j])}`;
            ctx.font = "bold 16px Arial";
            const pw = ctx.measureText(priceText).width;
            ctx.font = "bold 12px Arial";
            const lw = ctx.measureText("万円").width;
            const tw = pw + lw;
            const x = xs - (tw + 3) / 2;
            const y = ys - 5;

            // text: price
            ctx.font = "bold 16px Arial";
            ctx.fillStyle = colors[j];
            ctx.fillText(priceText, x, y);
            ctx.restore();

            ctx.font = "bold 12px Arial";
            ctx.fillStyle = colors[j];
            ctx.fillText("万円", x + pw + 1, y);
            ctx.restore();
          });
        });
      },
    };
  }
}
