import { Controller } from "@hotwired/stimulus";

// スクロール量で固定ヘッダーの状態を制御
//
// data-controller="top-navbar-state"
export default class extends Controller {
  connect() {
    this.pos = $(window).scrollTop();
    if (this.pos <= this.element.offsetHeight / 3) {
      this.pos = this.element.offsetHeight;
    } else {
      this.pos = 0;
    }

    // ヘッダーの直後が main でない場合は透過
    if (!document.querySelector(".site-header + main")) {
      this.#onScroll();
      this.#bind();
    }
  }

  #bind() {
    window.addEventListener("scroll", () => {
      this.#onScroll();
    });
  }

  #onScroll() {
    const scrollPos = $(window).scrollTop();
    // ヘッダーの 1/3 スクロールしたら
    const offset = this.element.offsetHeight / 3;

    if (this.pos > offset && scrollPos <= offset) {
      // スクロールしてない: 透過
      this.element.classList.add("faded");
    } else if (this.pos <= offset && scrollPos > offset) {
      // スクロールした: 不透過
      this.element.classList.remove("faded");
    }
    this.pos = scrollPos;
  }
}
