import { Controller } from "@hotwired/stimulus";

// modal: <%= render "shared/store_staffs/lazy_lightbox_modal" %>
//
// data-controller="lazy-lightbox-modal"
// data-lazy-lightbox-modal-img-src="URL"
export default class extends Controller {
  connect() {
    this.img = document
      .getElementById("lazy-lightbox-modal")
      .querySelector("img.placeholder");
    if (!this.img) return;

    this.src = this.data.get("img-src");
    this.bind();
  }

  bind() {
    this.element.addEventListener("click", () => {
      this.img.src = this.src;
    });
  }
}
