import { BootstrapWrapper } from "./BootstrapWrapper";

export class BootstrapWrapperBootstrap4 implements BootstrapWrapper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getJQuery: () => any = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const jQuery = (window as any).jQuery as any;
    return jQuery;
  };

  showModal: (modalElement: HTMLElement) => void = (modalElement) => {
    const jQuery = this.getJQuery();
    jQuery(modalElement).modal("show");
  };

  hideModal: (modalElement: HTMLElement) => void = (modalElement) => {
    const jQuery = this.getJQuery();
    jQuery(modalElement).modal("hide");
  };
}
