import { Controller } from "@hotwired/stimulus";
import autocomplete from "autocompleter";

// 補完するドメイン
const list = [
  "gmail.com",
  "docomo.ne.jp",
  "ezweb.ne.jp",
  "yahoo.co.jp",
  "i.softbank.jp",
  "softbank.ne.jp",
  "t.vodafone.ne.jp",
  "disney.ne.jp",
  "uqmobile.jp",
  "au.com",
  "augps.ezweb.ne.jp",
  "biz.ezweb.ne.jp",
  "biz.au.com",
  "dwmail.jp",
  "emnet.ne.jp",
  "emobile-s.ne.jp",
  "emobile.ne.jp",
  "googlemail.com",
  "hotmail.co.jp",
  "hotmail.com",
  "icloud.com",
  "ido.ne.jp",
  "jp-c.ne.jp",
  "jp-d.ne.jp",
  "jp-h.ne.jp",
  "jp-k.ne.jp",
  "jp-n.ne.jp",
  "jp-q.ne.jp",
  "jp-r.ne.jp",
  "jp-s.ne.jp",
  "jp-t.ne.jp",
  "live.jp",
  "me.com",
  "mineo.jp",
  "mopera.net",
  "nifty.com",
  "outlook.com",
  "outlook.jp",
  "pdx.ne.jp",
  "sky.tkc.ne.jp",
  "sky.tkk.ne.jp",
  "sky.tu-ka.ne.jp",
  "vodafone.ne.jp",
  "wcm.ne.jp",
  "willcom.com",
  "y-mobile.ne.jp",
  "yahoo.ne.jp",
  "ybb.ne.jp",
  "ymobile.ne.jp",
  "ymobile1.ne.jp",
  "mac.com",
  "aol.com",
  "msn.com",
  "rakuten.jp",
  "yokohama-toyopet.jp",
];

// メアドの @ 以降を補完してくれるやつ
// input要素に刺す
// data-controller="mailaddress-complete"
export default class extends Controller {
  connect() {
    this.isComposition = false;
    this.autocomplete = null;
    this.domains = list.map((x) => {
      return { name: x };
    });
    this.bind();
  }

  bind() {
    this.element.addEventListener("input", () => this.input());
    // IMEの変換・予測中の入力を無視するため composition* を監視
    this.element.addEventListener("compositionstart", () => {
      this.isComposition = true;
    });
    this.element.addEventListener("compositionend", () => {
      this.isComposition = false;
      this.refreshResults();
    });
  }

  input() {
    if (this.isComposition) return;
    this.refreshResults();
  }

  refreshResults() {
    const idx = this.element.value.indexOf("@");
    if (idx === -1) {
      if (this.autocomplete == null) return;
      this.autocomplete.destroy();
      this.autocomplete = null;
      return;
    }
    if (this.autocomplete != null) return;

    const namePart = this.element.value.substring(0, idx + 1);

    this.autocomplete = autocomplete({
      input: this.element,
      minLength: 2,
      debounceWaitMs: 80,
      preventSubmit: true,
      onSelect: (item, inputfield) => {
        inputfield.value = namePart + item.name;
      },
      fetch: (text, callback) => {
        const match = text.slice(namePart.length).toLowerCase();
        callback(
          this.domains.filter((x) => {
            return x.name.toLowerCase().indexOf(match) === 0;
          })
        );
      },
      render: (item, currentValue) => {
        const inputName = currentValue.slice(namePart.length);
        // 入力と候補が一致するものは無視
        if (item.name == inputName) return;

        const itemElement = document.createElement("div");
        itemElement.classList.add("result");
        itemElement.appendChild(document.createElement("strong")).innerText =
          namePart;
        // ドメインのマッチした箇所のハイライト
        const html = item.name.replace(
          inputName.toLowerCase(),
          (match) => "<strong>" + match + "</strong>"
        );
        itemElement.innerHTML += html;
        return itemElement;
      },
    });
  }
}
