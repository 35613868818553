import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mileage", "exchangedMileage", "totalMileage"];
  static values = {
    mileageInDatabase: Number,
    exchangedMileageInDatabase: Number,
    maintenanceOdometer: Number,
  };

  initialize() {
    this.lastValidationMessages = "";
  }

  connect() {
    this.refreshTotalMileage();

    this.mileageTarget.addEventListener("focus", () => {
      if (this.mileageTarget.validity.valid) return;
      this.mileageTarget.reportValidity();
    });
  }

  refreshTotalMileage() {
    this.validateInputs();

    const mileage = parseInt(this.mileageTarget.value, 10) || 0;
    const exchangedMileage =
      parseInt(this.exchangedMileageTarget.value, 10) || 0;

    this.totalMileageTarget.textContent = (
      mileage + exchangedMileage
    ).toLocaleString();
  }

  validateInputs() {
    const mileageInDB = this.mileageInDatabaseValue;
    const exchangedMileageInDB = this.exchangedMileageInDatabaseValue;
    const mileage = parseInt(this.mileageTarget.value, 10);
    const exchangedMileage = parseInt(this.exchangedMileageTarget.value, 10);

    // 初回登録時は rails の validation に任せる
    if (!mileageInDB) {
      return;
    }
    // 走行距離が入力されてない場合は rails の validation に任せる
    if (!mileage) {
      return;
    }
    const totalMileage = mileage + (exchangedMileage || 0);
    const totalMileageInDB = mileageInDB + (exchangedMileageInDB || 0);
    const maintenanceOdometer = this.maintenanceOdometerValue;
    const validationMessages = [];

    // 走行距離が最新の整備履歴よりも短い場合は警告表示
    if (totalMileage < maintenanceOdometer) {
      if (exchangedMileage) {
        validationMessages.push(
          "合計の走行距離が最新の整備履歴より短くなっています"
        );
      } else {
        validationMessages.push("走行距離が最新の整備履歴より短くなっています");
      }
    }

    // 走行距離が前回より短い場合は警告表示
    if (totalMileage < totalMileageInDB) {
      if (exchangedMileage) {
        validationMessages.push("合計の走行距離が前回より短くなっています");
      } else {
        validationMessages.push("走行距離が前回より短くなっています");
      }
    }

    // 走行距離が前回より10万km以上増加した場合は警告表示
    if (totalMileage > totalMileageInDB + 100000) {
      if (exchangedMileage) {
        validationMessages.push(
          "合計の走行距離が前回より10万km以上増加しています"
        );
      } else {
        validationMessages.push("走行距離が前回より10万km以上増加しています");
      }
    }

    this.mileageTarget.setCustomValidity(validationMessages.join("\n"));
    this.mileageTarget.reportValidity();
    this.lastValidationMessages = validationMessages;
  }
}
