interface Vector2 {
  x: number;
  y: number;
}

export default class {
  scrollElement: HTMLElement;
  offset: Vector2;

  constructor(scrollElement: HTMLElement) {
    this.scrollElement = scrollElement;
    const { x, y } = scrollElement.getBoundingClientRect();
    this.offset = {
      x: x,
      y: y,
    };
  }

  /**
   *
   * @param {HTMLElement} target scroll to
   * @param {number} duration in msec
   */
  scroll(target: HTMLElement, duration: number): void {
    const from: Vector2 = {
      x: this.scrollElement.scrollLeft,
      y: this.scrollElement.scrollTop,
    };
    const { x, y }: Vector2 = target.getBoundingClientRect();
    const delta: Vector2 = {
      x: x - this.offset.x,
      y: y - this.offset.y,
    };

    let last = 0;
    // イベント発火時に実行中の requestAnimationFrame をスキップ
    let amount = 1;

    const update = (ms: number): void => {
      const dt = ms - last;
      last = ms;
      amount += dt / duration;
      if (amount > 1.0) amount = 1.0;
      const t = this.easeOutQuart(amount);

      const nx = this.lerp(from.x, from.x + delta.x, t);
      const ny = this.lerp(from.y, from.y + delta.y, t);
      this.scrollElement.scrollTo(nx, ny);

      if (amount < 1.0) {
        window.requestAnimationFrame(update);
      }
    };
    const init = (ms: number): void => {
      last = ms;
      amount = 0;
      update(ms);
    };

    window.requestAnimationFrame(init);
  }

  private lerp(a: number, b: number, t: number): number {
    return a + (b - a) * t;
  }
  // https://gist.github.com/gre/1650294
  // 1 - (x - 1)^4
  private easeOutQuart(t: number): number {
    return 1 - --t * t * t * t;
  }
}
