import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="exhibited-car-restored-position"
 *
 * 有無の切り替え
 *   data-action="exhibited-car-restored-position#changed"
 * 選択フォーム
 *   data-exhibited-car-restored-position-target="container"
 * チェックボックス群
 *   data: { exhibited_car_restored_position_target: "positions"}
 */
export default class extends Controller {
  static targets = ["toggle", "container", "positions"];

  connect() {
    if (this.toggleTarget.checked) return;
    this.setActive(false, 0);
  }

  changed(ev) {
    this.setActive(ev.currentTarget.checked);
  }

  setActive(enabled, duration = 250) {
    if (enabled) {
      $(this.containerTarget).slideDown(duration);
    } else {
      $(this.containerTarget).slideUp(duration);
    }

    this.positionsTargets.forEach((el) => {
      el.disabled = !enabled;
    });
  }
}
