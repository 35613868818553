import { useState, useEffect, useCallback } from "react";

interface ScrollPosition {
  x: number;
  y: number;
  direction: null | "down" | "up";
}

export const useScroll = (): ScrollPosition => {
  const [scroll, setScroll] = useState<ScrollPosition>({
    x: document.body.getBoundingClientRect().left,
    y: document.body.getBoundingClientRect().top,
    direction: null,
  });

  const handleScrollEvent = useCallback((e: Event) => {
    const target = e.target as Element;
    setScroll((prev) => ({
      x: target.scrollLeft,
      y: -target.scrollTop,
      direction: prev.y > -target.scrollTop ? "down" : "up",
    }));
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent, true);
    return () => window.removeEventListener("scroll", handleScrollEvent, true);
  }, [handleScrollEvent]);

  return scroll;
};

export default useScroll;
