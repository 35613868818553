import { filterXSS } from "xss";
import Autolinker from "autolinker";

const linker = new Autolinker({
  sanitizeHtml: true,
  urls: {
    schemeMatches: true,
    wwwMatches: false,
    tldMatches: false,
  },
  email: false,
  phone: false,
  mention: false,
  hashtag: false,
  stripPrefix: false,
  stripTrailingSlash: false,
  replaceFn: (match) => {
    // url 以外はリンクにしない。
    if (match.getType() != "url") {
      return false;
    }

    // http 以外はリンクにしない。
    const href = match.getAnchorHref();
    if (!href.startsWith("https") && !href.startsWith("http")) {
      return false;
    }

    // アプリ用に data-action を追加。
    const tag = match.buildTag();
    tag.setAttr("data-action", "open-url-in-app#open");
    return tag;
  },
});

/**
 * Twilio Conversation の Message 内の URL をリンクにした HTML を返す。
 * open-url-in-app を使うには、先祖の要素に `data-controller="open-url-in-app"` が必要。
 * @param messageBody Message の body
 * @returns dangerouslySetInnerHTML に渡すためのオブジェクト
 */
export function getConversationMessageHtml(messageBody: string): {
  __html: string;
} {
  const linkHtml = linker.link(messageBody);

  // 念の為 xss フィルターを通す。
  const html = filterXSS(linkHtml, {
    whiteList: {
      a: ["href", "target", "rel", "data-action"],
    },
  });

  return { __html: html };
}
