import { Controller } from "@hotwired/stimulus";

/**
 * お問い合わせページ (/contact)
 * data-controller="contact"
 * 送信ボタン: data-contact-target="submit"
 */
export default class extends Controller {
  submit() {
    const q = new URLSearchParams(window.location.search);
    const exhibited_car_id = q.get("contact[exhibited_car_id]");
    const submit = document.querySelector("[data-formrun-submitting-text]");

    window.requestAnimationFrame(() => {
      // 送信中のテキストになってれば送信した事にする
      // 結局外部サービスでコールバックもないので厳密でなくていい
      if (submit.dataset.formrunSubmittingText != submit.textContent) return;
      document.cookie = `transaction_exhibited_car_id=${exhibited_car_id}`;
    });
  }
}
