import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // グレードのセレクトボックスが更新される度にここが呼ばれるはず
  connect() {
    const options = Array.from(this.element.options).filter(
      (option) => option.value && option.value != -1
    );

    // 選択可能オプションが1件しか無い場合は選択済みにする
    if (options.length == 1) {
      options[0].selected = true;
    }
  }
}
