/**
 * @param {number} size
 * @returns {string}
 */
export function getFileSizeForHuman(size) {
  const kb = 1024;
  const mb = Math.pow(kb, 2);

  let target = null;
  let unit = "byte";

  if (size >= mb) {
    target = mb;
    unit = "MB";
  } else if (size >= kb) {
    target = kb;
    unit = "KB";
  }

  let res = target != null ? Math.floor((size / target) * 100) / 100 : size;
  res = res + unit;

  return res;
}
