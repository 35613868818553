import React, { ReactNode } from "react";

interface Props {
  loading: boolean;
  children?: ReactNode;
}

const Loading: React.VFC<Props> = (props: Props): JSX.Element => {
  const { loading, children } = props;
  return loading ? (
    <div className="talkroom no-gutter-tab loading">
      <span>
        <i className="fa fa-spinner fa-spin" />
      </span>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loading;
