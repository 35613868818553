import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Rails from "@rails/ujs";
import { createSameNameHiddenInput } from "../../shared/createSameNameHiddenInput";

/** 加工した画像などの blob をアップロードできるようにする form
 *
 * <input type="file" data-blob-url="blob:…"> で選択したファイルの代わりに blob がアップロードされる。
 * data-blob-url の設定は mypage-listing-car-images-form 等で。
 *
 * 使い方:
 * - form の data-controller に blob-upload-form を追加する。
 * - form の送信ボタンを form.submit ではなく form.button type: 'button' にする。
 *   - submit だと先に本来のダイレクトアップロードが動いてしまうため。
 *   - `require("@rails/activestorage").start();` を消せば止められるけど、元のダイレクトアップロードが使えなくなってしまう。
 * - form の送信ボタンの data-action に blob-upload-form#submit を追加する。
 * - form.file_field に direct_upload: true と data-blob-name にファイル名を追加する。
 *
 * イベント:
 * - blob-upload-form:start 開始時
 * - blob-upload-form:success 成功時。 event.detail は [response, status, xhr] (rails-ujs の ajax:success と同じ)
 * - blob-upload-form:error 失敗時
 */
export default class extends Controller {
  static targets = ["form"];

  /**
   * @returns {HTMLFormElement}
   */
  form() {
    return this.hasFormTarget ? this.formTarget : this.element;
  }

  connect() {
    // Safari だとページバック時に残ってしまう。 Chrome だと不要っぽい。
    // https://github.com/rails/rails/blob/f95c0b7e96eb36bc3efc0c5beffbb9e84ea664e4/actionview/app/assets/javascripts/rails-ujs/start.coffee#L24-L32
    window.addEventListener("pageshow", (_) => {
      delete this.form().dataset.blobUploading;
    });
  }

  /**
   * @param {string} type イベント名
   * @param {any} detail event.detail
   */
  dispatchEvent(type, detail = {}) {
    const event = new CustomEvent(`blob-upload-form:${type}`, { detail });
    this.form().dispatchEvent(event);
  }

  /**
   * @param {Event} event
   */
  submit() {
    this.submitForm(this.form());
  }

  /**
   * @param {HTMLFormElement} form
   */
  async submitForm(form) {
    if (form.dataset.blobUploading) {
      return;
    }
    form.dataset.blobUploading = "true";
    this.dispatchEvent("start");

    try {
      await uploadBlobsForForm(form);
      const success = await submitFormAndWait(form);
      this.dispatchEvent("success", success.detail);
    } catch (error) {
      // イベント受け取り忘れると気づけないので、ここでログに出す。
      console.warn(error);
      this.dispatchEvent("error", error);
    }

    delete form.dataset.blobUploading;
  }
}

/**
 * @param {HTMLFormElement} form
 */
async function uploadBlobsForForm(form) {
  const elements = Array.from(form.elements);
  const blobInputs = elements.filter(
    (x) => x.dataset.blobUrl && x.dataset.directUploadUrl
  );

  for (const input of blobInputs) {
    disableSameNameFormElements(form, input);

    const attributes = await uploadBlobUrl(
      input.dataset.blobUrl,
      input.dataset.blobName,
      input.dataset.directUploadUrl
    );
    createSameNameHiddenInput(input, attributes.signed_id);

    // form で送信されないようにファイルの選択をクリアする。
    input.value = "";
    if (input.dataset.blobUrl) {
      URL.revokeObjectURL(input.dataset.blobUrl);
    }
    input.dataset.blobUrl = "";
  }
}

/**
 * 同名フィールドが複数あったら、送信されないように target 以外を disabled にする。
 * @param {HTMLFormElement} form
 * @param {HTMLInputElement} target
 */
function disableSameNameFormElements(form, target) {
  // form.elements[] は複数あったらリストで返ってくる。
  Array.from(form.elements[target.name])
    .filter((x) => x != target)
    .forEach((x) => {
      x.disabled = true;
    });
}

/**
 * remote な form だったら、 form を送信して ajax:success のイベントを返す。
 * @param {HTMLFrameElement} form
 */
function submitFormAndWait(form) {
  return new Promise((resolve, reject) => {
    if (form.dataset.remote === "true") {
      const onSuccess = (event) => {
        form.removeEventListener("ajax:success", onSuccess);
        form.removeEventListener("ajax:error", onError);
        resolve(event);
      };
      const onError = (event) => {
        form.removeEventListener("ajax:success", onSuccess);
        form.removeEventListener("ajax:error", onError);
        reject(event);
      };
      form.addEventListener("ajax:success", onSuccess);
      form.addEventListener("ajax:error", onError);

      // form.submit() だと XHR にならないので、 rails-ujs を呼び出す。
      Rails.fire(form, "submit");
    } else {
      // そのまま画面遷移するので resolve しない。
      form.submit();
    }
  });
}

/**
 * @param {string} blobUrl
 * @param {string} blobName
 * @param {string} directUploadUrl
 * @returns {Promise<{signed_id: string}>}
 */
async function uploadBlobUrl(blobUrl, blobName, directUploadUrl) {
  const blobResponse = await fetch(blobUrl);
  const blob = await blobResponse.blob();

  // name がないと /rails/active_storage/direct_uploads が 500 になる。
  // activestorage (6.0.0) app/models/active_storage/blob.rb:79 で ArgumentError (missing keyword: filename)
  blob.name = blobName || "_";

  const uploader = new Uploader(blob, directUploadUrl);
  return await uploader.uploadAsync();
}

// https://edgeguides.rubyonrails.org/active_storage_overview.html#integrating-with-libraries-or-frameworks
class Uploader {
  constructor(file, url) {
    this.file = file;
    this.url = url;
    this.upload = new DirectUpload(this.file, this.url, this);
  }

  uploadAsync() {
    return new Promise((resolve, reject) => {
      this.upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(_event) {
    // TODO: 進捗状況を表示するならここで通知する。
  }
}
