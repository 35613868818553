import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import { toCanvas } from "qrcode";

/**
 * usage:
 *  <div class="text-center" data-controller="qr-renderer" data-qr-renderer-data="<%= mypage_talkroom_url(current_store_staff.code) %>">
 *   <canvas class="w-75 h-75 d-block mx-auto" width="480" height="480" data-qr-renderer-target="canvas"></canvas>
 *  </div>
 */
export default class extends Controller {
  static targets = ["canvas"];

  connect() {
    this.unobserve = useIntersection(this)[1];
  }

  appear() {
    const opt = {
      width: this.canvasTarget.width,
      height: this.canvasTarget.height,
    };
    toCanvas(this.canvasTarget, this.data.get("data"), opt);
    this.unobserve();
  }
}
