import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

/**
 * 車両のお気に入りボタンを制御する。
 *
 * 使用例:
 * ```erb
 * <button class="btn btn-icon rounded-circle form-check-like">
 *   <%
 *     check_box_id = "exhibited_cars_#{exhibited_car.id}"
 *   %>
 *   <%= check_box_tag exhibited_car_likes_path(exhibited_car),
 *       exhibited_car.id,
 *       exhibited_car.liked?(current_user, GuestExhibitedCarLikeCookie.new(cookies)),
 *       data: {
 *         controller: "exhibited-car-favorites",
 *         exhibited_cars: exhibited_car.id,
 *       },
 *       id: check_box_id %>
 *   <%= label_tag check_box_id, "", class: "icon icon-heart" %>
 * </button>
 * ```
 */
export default class extends Controller {
  connect() {
    // checkbox name に URL 入れておく
    this.src = this.element.getAttribute("name");
    if (this.element.dataset.countSelector) {
      this.count = document.querySelector(this.element.dataset.countSelector);
    }
    this.bind();
  }

  bind() {
    this.element.addEventListener("change", () => {
      this.request(this.src, this.element.checked);
      this.sync(this.element.value, this.element.checked);
    });
  }

  /**
   * ページ内の同じ車両を表示しているお気に入りボタンを連動させる。
   * `data-exhibited-cars` 属性 (`data: { exhibited_cars: }`) に車両IDを指定した場合だけ有効。
   * @param {string} id exhibited_car.id
   * @param {boolean} faved お気に入り登録/解除フラグ
   * @returns
   */
  sync(id, faved) {
    const elements = document.querySelectorAll(`[data-exhibited-cars="${id}"]`);
    const arrayElms = Array.from(elements);
    arrayElms.forEach((e) => {
      e.checked = faved;
    });
  }

  /**
   * @param {string} src "/exhibited_cars/:id/likes"形式のURL
   * @param {boolean} faved お気に入り登録/解除フラグ
   * @returns
   */
  request(src, faved) {
    const method = faved ? "POST" : "DELETE";

    Rails.ajax({
      type: method,
      url: src,
      // https://github.com/rails/rails/commit/ad3a47759e67a411f3534309cdd704f12f6930a7#diff-5add1333e3835d377f5710902a10f6c97e4fceb2e29860bf899c6aa77ac04f8bR18-R22
      success: () => {},
      error: () => {},
      complete: () => {
        this.element.checked = faved;
        const x = faved ? 1 : -1;
        this.updateCount(x);
      },
    });
  }

  /**
   * @param {int} delta 負の値で減算
   * @returns
   */
  updateCount(delta) {
    if (!this.count) return;

    let x = parseInt(this.count.textContent);
    this.count.textContent = x + delta;
  }
}
