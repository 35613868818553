import { Controller } from "@hotwired/stimulus";

/**
 * remote な form のレスポンスで mypage-likes が刺さっている element を置き換える。
 * id で置き換えているので id の指定が必須。
 * 使用例:
 * ```
 * <div id="replace-frame" data-controller="mypage-likes">
 *   <form data-remote="true" data-action="ajax:send->mypage-likes#loading ajax:success->mypage-likes#formSuccess">
 * </div>
 * ```
 */
export default class extends Controller {
  loading() {
    this.element.style.opacity = 0.6;
  }

  formSuccess(event) {
    const [response, ,] = event.detail;

    const frameId = this.element.id;
    const newContents = response.getElementById(frameId);
    this.element.replaceWith(newContents);
  }
}
