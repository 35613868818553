import { Controller } from "@hotwired/stimulus";

const FILE_MAX_SIZE = 1024 * 1024 * 5; // 5MB

/** トーク一斉送信の添付ファイルinputを動的に増減する
 *
 * 使用例:
 * ```
 * <form data-controller="messages-broadcast-images-list">
 *   <ol data-messages-broadcast-images-list-target="parent">
 *     <li data-messages-broadcast-images-list-target="item">
 *       <input data-action="change->messages-broadcast-images-list#add" type="file">
 *       <button data-action="click->messages-broadcast-images-list#remove">...</button>
 *     </li>
 *     <template data-messages-broadcast-images-list-target="template">...</template>
 *   </ol>
 *   <span data-messages-broadcast-images-list-target="fileCount">...</span>
 * </form>
 * ```
 */
export default class extends Controller {
  static targets = ["item", "template", "parent", "fileCount"];

  connect() {
    // 添付ファイルの数を格納する
    this.fileCount = parseInt(this.data.get("count"), 10);
    this.viewFileCount();
  }

  add(event) {
    // ファイルサイズが超過した場合は破棄する
    if (event.target.files[0].size > FILE_MAX_SIZE) {
      this.remove(event);
      this.addItem();
      return;
    }

    const max = parseInt(this.data.get("max"), 10);
    const itemCount = this.itemTargets.length;
    if (itemCount == max) {
      this.fileCount++;
      this.viewFileCount();
      return;
    }
    const lastItem = this.itemTargets[itemCount - 1];
    if (!lastItem.contains(event.target)) {
      return;
    }

    this.addItem();
    this.fileCount++;

    this.viewFileCount();
  }

  addItem() {
    const item = document.importNode(this.templateTarget.content, true);
    this.parentTarget.appendChild(item);
  }

  remove(event) {
    const removingItem = this.itemTargets.find((x) => x.contains(event.target));
    removingItem.remove();
    this.fileCount--;

    this.viewFileCount();

    // 要素を削除した際に追加用の要素が存在しない場合は要素を追加する
    // (削除実行後はitemTargetsが常に１つ多いはず)
    if (this.itemTargets.length == this.fileCount) {
      this.addItem();
    }
  }

  /**
   * 添付可能なファイル数を表示する
   */
  viewFileCount() {
    const max = parseInt(this.data.get("max"), 10);

    if (this.fileCount == max) {
      this.fileCountTarget.textContent = "※これ以上ファイルを追加できません。";
      this.fileCountTarget.classList.add("text-danger");
      this.fileCountTarget.classList.remove("text-muted");
    } else {
      const count = max - this.fileCount;
      this.fileCountTarget.textContent = `同時に送信できるファイルは${max}つ(残り${count}つ)までです。`;
      this.fileCountTarget.classList.remove("text-danger");
      this.fileCountTarget.classList.add("text-muted");
    }
  }
}
