import { Controller } from "@hotwired/stimulus";

/**
 * user の notification 表示用。
 * data-controller="notification-detail"
 */
export default class extends Controller {
  connect() {
    // notifications がある時(リスト内に PJAX で表示された時)だけ。
    if (document.getElementById("notifications")) {
      $(".close-notification").on("click", (ev) => {
        ev.preventDefault();
        $("#notification").slideUp(166);
        $("#notifications").slideUp(166);

        $.ajax({
          url: ev.currentTarget.href,
        }).done((html) => {
          $("#notifications").replaceWith(html);
          $("#notifications").hide().slideDown(166);
        });
      });
    }
  }
}
