import { Controller } from "@hotwired/stimulus";

/** 選択中のラジオボタンの再クリックで選択解除する。
 *
 * グループごとに `data-controller="toggle-radio"` で囲う。
 * 各ラジオボタンに `data-toggle-radio-target="button"` をつける。
 */
export default class extends Controller {
  static targets = ["button"];

  /** 最後に選択されていた時のラジオボタンの値。
   * @type {String}
   */
  #lastValue = "";

  connect() {
    /** @type {HTMLInputElement[]} */
    const buttons = this.buttonTargets;
    if (buttons.length == 0) {
      console.error("toggle-radio.button が設定されていない!");
      return;
    }

    const name = buttons[0].name;
    const form = buttons[0].form;
    if (
      !buttons.every((x) => x.name === name) ||
      !buttons.every((x) => x.form === form)
    ) {
      console.error("違うグループのラジオボタンが混ざってる!", buttons);
      return;
    }
    /** @type {RadioNodeList} */
    const radioList = form.elements[name];

    // 未選択の場合は空文字列になる。
    this.#lastValue = radioList.value;

    buttons.forEach((button) =>
      button.addEventListener("click", this.#onClick)
    );
  }

  disconnect() {
    /** @type {HTMLInputElement[]} */
    const buttons = this.buttonTargets;
    buttons.forEach((button) =>
      button.removeEventListener("click", this.#onClick)
    );
  }

  /**
   * @param {MouseEvent} event
   */
  #onClick = (event) => {
    /** @type {HTMLInputElement} */
    const target = event.target;

    if (this.#lastValue === target.value) {
      // click イベントが来る時点で checked の値は変わっているっぽい。ここで checked を外せる。
      target.checked = false;
      this.#lastValue = "";
    } else {
      this.#lastValue = target.value;
    }
  };
}
