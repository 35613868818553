import { Controller } from "@hotwired/stimulus";

/** リアルタイムで文字数を表示する。
 *
 *  <div data-controller="character-counter">
 *    <span class="text-xs text-muted"><span data-character-counter-target="count"></span>/1500</span>
 *    <%= f.text_area :description, class: "form-control", data: { action: "input->character-counter#count", target: "character-counter.text" } %>
 */

export default class extends Controller {
  static targets = ["text", "count"];

  connect() {
    this.#updateCount();
  }

  count() {
    this.#updateCount();
  }

  #updateCount() {
    const length = this.textTarget.value.length;
    this.countTarget.textContent = `${length}`;
  }
}
