import React from "react";
import { getVideoInputDevices } from "./getVideoInputDevices";
import { BrowserCodeReader } from "./BrowserCodeReader";
import { ShakenshouReader } from "./ShakenshouReader";
import { CarInfo, CarSpec, CarClass } from "./QRReader/types";
import { VideoCanvas } from "./VideoCanvas";
import { determineQRIndex } from "./QRReader/determineClassification";
import { dispatchQrReaderErrorEvent } from "./events";

/* eslint-disable @typescript-eslint/no-var-requires */
const qrNormal = require("../images/qr-normal.png");
/* eslint-enable @typescript-eslint/no-var-requires */

interface Props {
  onCompleteScan: (carInfo: CarInfo, carSpec: CarSpec) => void;
}

interface State {
  foundVideoDevice: boolean;
  isCompleteScan: boolean;
  carClass: CarClass | null;

  foundHutsuuQr2no1: boolean;
  foundHutsuuQr2no2: boolean;
  foundHutsuuQr3no1: boolean;
  foundHutsuuQr3no2: boolean;
  foundHutsuuQr3no3: boolean;
  foundHutsuuQr4: boolean;
  foundKeiQr1: boolean;
  foundKeiQr2: boolean;
  foundKeiQr3: boolean;
  foundKeiQr4: boolean;
  foundKeiQr5: boolean;
  foundKeiQr6: boolean;
}

export class QRReaderFromVideo extends React.Component<Props, State> {
  browserCodeReader: BrowserCodeReader;
  shakenshouReader: ShakenshouReader;
  state: State = {
    foundVideoDevice: false,
    isCompleteScan: false,
    carClass: null,

    foundHutsuuQr2no1: false,
    foundHutsuuQr2no2: false,
    foundHutsuuQr3no1: false,
    foundHutsuuQr3no2: false,
    foundHutsuuQr3no3: false,
    foundHutsuuQr4: false,
    foundKeiQr1: false,
    foundKeiQr2: false,
    foundKeiQr3: false,
    foundKeiQr4: false,
    foundKeiQr5: false,
    foundKeiQr6: false,
  };

  constructor(props: Props) {
    super(props);
    const foundQrStrings = new Set<string>();
    this.browserCodeReader = new BrowserCodeReader((result, error) => {
      if (result === null) {
        // 何のエラーが出るか不明。エラーが出たらそこで QR コードの読み込みは終わる。
        console.error(error);
        dispatchQrReaderErrorEvent();
        return;
      }

      // 同じ QR コードを何度も読み込むのは無駄なので、ここで止める。
      if (foundQrStrings.has(result.string)) return;
      foundQrStrings.add(result.string);

      this.updateQrProgress(result.string);
      this.shakenshouReader.yonda(result.string);
      const carInfo = this.shakenshouReader.shakenInfo.getCarInfo();
      const carSpec = this.shakenshouReader.shakenInfo.getCarSpec();
      if (carInfo && carSpec) {
        this.props.onCompleteScan(carInfo, carSpec);
        this.setState({ isCompleteScan: true });
        this.browserCodeReader.reset();
      }
    });
    this.shakenshouReader = new ShakenshouReader();
  }

  componentDidMount(): void {
    this.setupVideoDevice().catch((reason) => {
      console.error({ reason });
      dispatchQrReaderErrorEvent({ videoInputDeviceNotFound: true });
    });
  }

  async setupVideoDevice(): Promise<void> {
    const videoInputDevices = await getVideoInputDevices();
    if (videoInputDevices.length > 0) {
      this.setState({ foundVideoDevice: true });
      await this.browserCodeReader.decodeFromVideoDevice(null, "video");
    } else {
      throw new Error("videoInputDevice が空");
    }
  }

  componentWillUnmount(): void {
    this.browserCodeReader.reset();
    this.shakenshouReader.shakenInfo.reset();
  }

  updateQrProgress(string: string): void {
    const qrIndex = determineQRIndex(string);
    if (!qrIndex) return;
    if (qrIndex.carClass === "hutsuu") {
      if (qrIndex.section === 2) {
        if (qrIndex.index === 1) {
          this.setState({ carClass: "hutsuu", foundHutsuuQr2no1: true });
        } else {
          this.setState({ carClass: "hutsuu", foundHutsuuQr2no2: true });
        }
      } else if (qrIndex.section === 3) {
        if (qrIndex.index === 1) {
          this.setState({ carClass: "hutsuu", foundHutsuuQr3no1: true });
        } else if (qrIndex.index === 2) {
          this.setState({ carClass: "hutsuu", foundHutsuuQr3no2: true });
        } else {
          this.setState({ carClass: "hutsuu", foundHutsuuQr3no3: true });
        }
      } else if (qrIndex.section === 4) {
        this.setState({ carClass: "hutsuu", foundHutsuuQr4: true });
      }
    } else {
      if (qrIndex.section === 1) {
        this.setState({ carClass: "kei", foundKeiQr1: true });
      } else if (qrIndex.section === 2) {
        this.setState({ carClass: "kei", foundKeiQr2: true });
      } else if (qrIndex.section === 3) {
        this.setState({ carClass: "kei", foundKeiQr3: true });
      } else if (qrIndex.section === 4) {
        this.setState({ carClass: "kei", foundKeiQr4: true });
      } else if (qrIndex.section === 5) {
        this.setState({ carClass: "kei", foundKeiQr5: true });
      } else if (qrIndex.section === 6) {
        this.setState({ carClass: "kei", foundKeiQr6: true });
      }
    }
  }

  hutsuuQrs(): React.ReactNode {
    if (this.state.carClass === "kei") return null;

    return (
      <div className="p-1">
        <div className="label">普通自動車</div>
        <div className="qr_list">
          <span className="group">
            <span className={this.state.foundHutsuuQr4 ? "ok" : ""}>
              <img className="unneeded" src={qrNormal} />
            </span>
          </span>

          <div className="border border-danger d-inline-block group">
            <span>
              <span className={this.state.foundHutsuuQr3no1 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
              <span className={this.state.foundHutsuuQr3no2 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
              <span className={this.state.foundHutsuuQr3no3 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
            </span>

            <span className="group">
              <span className={this.state.foundHutsuuQr2no1 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
              <span className={this.state.foundHutsuuQr2no2 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
            </span>
          </div>

          <span className="group">
            <span>
              <img className="unneeded" src={qrNormal} />
            </span>
            <span>
              <img className="unneeded" src={qrNormal} />
            </span>
          </span>
        </div>
      </div>
    );
  }

  keiQrs(): React.ReactNode {
    if (this.state.carClass === "hutsuu") return null;

    return (
      <div className="p-1">
        <div className="label">軽自動車</div>
        <div className="qr_list">
          <span className="group">
            <span className={this.state.foundKeiQr6 ? "ok" : ""}>
              <img className="unneeded" src={qrNormal} />
            </span>
          </span>
          <span className="group">
            <span className={this.state.foundKeiQr5 ? "ok" : ""}>
              <img className="unneeded" src={qrNormal} />
            </span>
          </span>
          <span className="group">
            <span className={this.state.foundKeiQr4 ? "ok" : ""}>
              <img className="unneeded" src={qrNormal} />
            </span>
          </span>
          <div className="border border-danger d-inline-block group">
            <span>
              <span className={this.state.foundKeiQr3 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
            </span>
            <span className="group">
              <span className={this.state.foundKeiQr2 ? "ok" : ""}>
                <img src={qrNormal} />
              </span>
            </span>
          </div>
          <span className="group">
            <span className={this.state.foundKeiQr1 ? "ok" : ""}>
              <img className="unneeded" src={qrNormal} />
            </span>
          </span>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    if (this.state.isCompleteScan) {
      return <p>読み取りが完了しました。車検証の情報入力画面に移動します</p>;
    }

    if (!this.state.foundVideoDevice) {
      // videoInputDevice 取得終わってない
      return <p>videoInputDevice取得中</p>;
    }

    const hutsuuQrs = this.hutsuuQrs();
    const keiQrs = this.keiQrs();
    return (
      <React.Fragment>
        <div className="qr_progress">
          {hutsuuQrs}
          {keiQrs}
        </div>
        <div className="qr_main">
          <VideoCanvas videoElementID="video" width="100%" height="100%" />
        </div>
      </React.Fragment>
    );
  }
}
