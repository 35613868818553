import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="menu-in-app"
 */
export default class extends Controller {
  static targets = ["linkTemplate"];

  /**
   * @returns {HTMLTemplateElement}
   */
  get linkTemplate() {
    return this.linkTemplateTarget;
  }

  connect() {
    // アプリから呼ばれる。
    window.updateMenu = ({ links }) => {
      this.updateMenu(links);
    };

    // アプリからメニュー情報を onLodadMenu に渡してもらうようにする。
    document.addEventListener("MobilicoAppLoaded", () => {
      window.MobilicoApp?.onLodadMenu("updateMenu");
    });
  }

  updateMenu(links) {
    // メニューにリンクを追加する。
    links.forEach(({ id, label }) => {
      const clone = this.linkTemplate.content.cloneNode(true);
      const link = clone.querySelector("[data-template-id='link']");
      link.textContent = label;
      link.addEventListener("click", () => {
        window.MobilicoApp?.onClickMenu(id);
      });
      this.linkTemplate.parentElement.appendChild(clone);
    });
  }
}
