import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "components/ErrorBoundary";
import { getTwilioConnection } from "shared/TwilioConnection";

interface Props {
  accessToken: string | null;
  conversationSids: string[];
  identity: string | null;
}

const ChatNotifyBadge: React.FC<Props> = (props: Props) => {
  const { accessToken, conversationSids, identity } = props;

  const [badge, setBadge] = useState<number>(0);

  useEffect(() => {
    if (accessToken === null) return;
    getTwilioConnection().setAccessToken(accessToken);
    getTwilioConnection().setIdentity(identity);
    getTwilioConnection().watchUnreadMessagesCount(setBadge, conversationSids);
    return () => getTwilioConnection().unwatchUnreadMessagesCount(setBadge);
  }, [accessToken, conversationSids, setBadge]);

  return (
    <> {badge !== 0 ? <span className="notify-badge">{badge}</span> : null}</>
  );
};

const ChatNotifyBadgeApp: React.FC<Props> = (props: Props) => {
  return (
    <ErrorBoundary>
      <ChatNotifyBadge {...props} />
    </ErrorBoundary>
  );
};

export default ChatNotifyBadgeApp;
