import { Controller } from "@hotwired/stimulus";
import Croppie from "croppie";
import { createSameNameHiddenInput } from "../../shared/createSameNameHiddenInput";

/** 選択したファイルを croppie で crop できるようにする。
 *
 * ```
 *  <div class="modal" tabindex="-1" role="dialog" data-croppie-target="modal">
 *    <div data-croppie-target="container"></div>
 *    <button data-action="croppie#crop">
 *  </div>
 *  <div data-croppie-target="delete">削除ボタン</div>
 *  <img data-croppie-target="previewImage">
 *  <%= form.file_field field, direct_upload: true,
 *        data: {
 *          blob_name: name,
 *          croppie_target: "fileInput",
 *        } %>
 * ```
 * form の送信は blob-upload-form で。
 *
 * scss に `@import "croppie/croppie";` が必要。
 */
export default class extends Controller {
  static targets = [
    "delete",
    "container",
    "fileInput",
    "modal",
    "previewImage",
  ];

  connect() {
    this.fileInputTarget.addEventListener("change", (_event) => {
      this.#fileSelect();
    });
    if (this.hasDeleteTarget) {
      this.deleteTarget.addEventListener("click", (event) => {
        event.preventDefault();
        this.delete();
      });
    }
  }

  #fileSelect() {
    const fileInput = this.fileInputTarget;
    if (fileInput.files.length < 1) {
      return;
    }
    const file = fileInput.files[0];

    // 同じファイルを選択した時にまた change イベントが来るように、ファイルの選択を解除する。
    fileInput.value = "";

    this.#createSmallBlob(file);
  }

  /**
   * @param {File} file
   */
  async #createSmallBlob(file) {
    if (this.hasModalTarget) {
      $(this.modalTarget).modal("show");
    }

    const blobUrl = URL.createObjectURL(file);

    if (this.hasModalTarget) {
      this.containerTarget.dataset.blobUrl = blobUrl;
      this.croppie = new Croppie(this.containerTarget, {
        viewport: {
          width: 280,
          height: 280,
          type: "circle",
        },
        boundary: {
          width: 300,
          height: 300,
        },
      });

      this.croppie.bind({
        url: blobUrl,
        // 全体が入るように最小ズームにする。
        zoom: 0,
      });

      $(this.modalTarget).one("hidden.bs.modal", () => {
        this.croppie?.destroy();
        this.croppie = undefined;
      });
    } else {
      this.#setBlobUrl(blobUrl);
    }
  }

  #setBlobUrl(blobUrl) {
    this.previewImageTarget.src = blobUrl;
    const fileInput = this.fileInputTarget;
    if (fileInput.dataset.blobUrl) {
      URL.revokeObjectURL(fileInput.dataset.blobUrl);
    }
    fileInput.dataset.blobUrl = blobUrl;
    this.element.dataset.formStatus = "preview";
  }

  async #saveCroppie() {
    if (this.croppie === undefined) {
      return;
    }

    if (this.containerTarget.dataset.blobUrl) {
      URL.revokeObjectURL(this.containerTarget.dataset.blobUrl);
    }

    const blob = await this.croppie.result({
      type: "blob",
      size: {
        width: 255,
        height: 255,
      },
    });
    const blobUrl = URL.createObjectURL(blob);
    this.#setBlobUrl(blobUrl);

    const modal = $(this.modalTarget);
    modal.modal("hide");
  }

  crop() {
    this.#saveCroppie();
  }

  // フォームの送信時に削除ができるように、hidden input を追加する。
  // そのままでは ActiveStorage が削除してくれないので、 rails 側の対応も必要。
  delete() {
    const fileInput = this.fileInputTarget;

    // 念のため、ファイルの選択を解除する。通常はここに来るまでに解除されているはず。
    fileInput.value = "";

    // input の直前に値が空の hidden input を追加する。
    createSameNameHiddenInput(fileInput, "");

    // setBlobUrl の中で formStatus が変わるので、その後で上書きする。
    this.#setBlobUrl("");

    this.element.dataset.formStatus = "blank";
  }
}
