import {
  parseKeiQR2,
  parseKeiQR3,
  parseKeiQR4,
  parseKeiQR5,
  parseKeiQR6,
} from "./parseKeiQRResults";
import { CarClass, QRIndex } from "./types";

export function determineClassification(string: string): CarClass {
  return string.startsWith("K") ? "kei" : "hutsuu";
}

// 最初のバージョン番号と、自動車登録番号の運輸支局があったら section2.1
// https://www.airia.or.jp/info/number/02.html
const hutsuu2no1 = /^\d+\/[\p{sc=Hiragana}\p{sc=Katakana}\p{sc=Han}]+/u;
// hutsuu2no2 は難しいから最後に判定する
// 車台番号から帳票種別まで
const hutsuu2no2 = /[^\\/]*?\/[^\\/]*?\/\d$/;

// バージョン情報から初度登録年月まで
// 初度登録年月までは長さ固定だろうから、型式の前半（？）らへんまで入れてもよいか？
const hutsuu3no1 = /^(\d+)\/(.{3}|-\x20\x20)\/(\*?\d+)?\/\d{6}\/\d{4}/;
// 型式から騒音規制まで
const hutsuu3no2 = /^.*?\/((\d{4}|-\x20{3}|\*\*\*\x20)\/){4}(\d{2}|-\x20)/;
// 駆動方式から燃料の種別コードまで
const hutsuu3no3 =
  /.\/.\/.\/(\d{4}|-\x20{3}|\*{3}\x20{1})\/(\d{5}|-\x20{4}|\*{3}\x20{2})\/\d{6}\/\d{2}$/;

// ブランク
const hutsuu4 = /1\/\/\/\/\/\//;

// 軽自動車の QR1 の中身は使わないので判定だけできるようにする。
// これだけ / で区切られていない。
const kei1 = /^K02/;

export function determineQRIndex(string: string): QRIndex | null {
  const carClass = determineClassification(string);
  if (carClass === "kei") {
    // 軽自動車は QR 分割されてないので parseKeiQR2 とかを使う
    if (parseKeiQR2(string)) {
      return { carClass, section: 2, index: 1 };
    } else if (parseKeiQR3(string)) {
      return { carClass, section: 3, index: 1 };
    } else if (parseKeiQR4(string)) {
      return { carClass, section: 4, index: 1 };
    } else if (parseKeiQR5(string)) {
      return { carClass, section: 5, index: 1 };
    } else if (parseKeiQR6(string)) {
      return { carClass, section: 6, index: 1 };
    } else if (string.match(kei1)) {
      return { carClass, section: 1, index: 1 };
    } else {
      return null;
    }
  } else {
    // 普通自動車は QR がどこで分割されているか分からないので parse とは別の正規表現を使う
    if (string.match(hutsuu3no1)) {
      return { carClass, section: 3, index: 1 };
    } else if (string.match(hutsuu3no2)) {
      return { carClass, section: 3, index: 2 };
    } else if (string.match(hutsuu3no3)) {
      return { carClass, section: 3, index: 3 };
    } else if (string.match(hutsuu2no1)) {
      return { carClass, section: 2, index: 1 };
    } else if (string.match(hutsuu2no2)) {
      return { carClass, section: 2, index: 2 };
    } else if (string.match(hutsuu4)) {
      return { carClass, section: 4, index: 1 };
    } else {
      return null;
    }
  }
}
