import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import getCurrentUrlAppendingIframeParam from "../../shared/getCurrentUrlAppendingIframeParam";

/** contact-thanks */
export default class extends Controller {
  connect() {
    const url = getCurrentUrlAppendingIframeParam();

    if (!url) return;
    Turbo.visit(url, { action: "replace" });
  }
}
