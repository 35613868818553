import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "components/ErrorBoundary";

// 新しい typescript でエラーが出るので、回避する。
// https://github.com/streamich/react-use/issues/2074#issuecomment-982044510
// import { useLocalStorage } from "react-use";
import useLocalStorage from "react-use/lib/useLocalStorage";

interface Identifier {
  id: string;
  name: string;
}

interface User extends Identifier {
  nameKana: string;
}

interface Car extends Identifier {
  numberPlateNumber: string;
  trialPublishing: boolean;
  verified: boolean;
  status: number;
  statusLabel: string;
}

interface Record {
  user: User;
  car: Car;
}

interface Props {
  records: Record[];
  storeStaffDomainPath: string;
}

const SEARCH_HISTORY_KEY = "StoreStaffUserSearch";
const SEARCH_HISTORY_LIMIT = 20;

const StoreStaffUserSearch: React.VFC<Props> = (props: Props) => {
  const { records, storeStaffDomainPath } = props;
  const [input, setInput] = useState<string>("");
  const [candidates, setCandidates] = useState<{ [name: string]: Record[] }>(
    {}
  );
  const [showingResults, setShowingResults] = useState<Record[]>([]);
  const isShowingResult = useMemo<boolean>(() => {
    return showingResults.length > 0;
  }, [showingResults]);
  const [histories, setHistories] = useLocalStorage<string[]>(
    SEARCH_HISTORY_KEY,
    []
  );

  useEffect(() => {
    // 何か入力したならば結果表示でなく検索中にする
    if (isShowingResult) setShowingResults([]);

    if (records.length === 0 || input === "") {
      setCandidates({});
      return;
    }

    const found: { [name: string]: Record[] } = {};
    records.forEach((record) => {
      // 氏名から
      if (record.user.name.indexOf(input) === 0) {
        if (found[record.user.name] === undefined) {
          found[record.user.name] = [record];
        } else {
          found[record.user.name].push(record);
        }
      }

      // 氏名ふりがなから
      if (record.user.nameKana.indexOf(input) === 0) {
        if (found[record.user.nameKana] === undefined) {
          found[record.user.nameKana] = [record];
        } else {
          found[record.user.nameKana].push(record);
        }
      }

      // 車名から
      if (record.car.name.indexOf(input) === 0) {
        if (found[record.car.name] === undefined) {
          found[record.car.name] = [record];
        } else {
          found[record.car.name].push(record);
        }
      }

      // ナンバーから
      if (record.car.numberPlateNumber.indexOf(input) === 0) {
        if (found[record.car.numberPlateNumber] === undefined) {
          found[record.car.numberPlateNumber] = [record];
        } else {
          found[record.car.numberPlateNumber].push(record);
        }
      }
    });

    setCandidates(found);
  }, [input]);

  let view;
  if (isShowingResult) {
    view = (
      <SearchResult
        records={showingResults}
        storeStaffDomainPath={storeStaffDomainPath}
      />
    );
  } else if (input === "") {
    view = <SearchHistory histories={histories ?? []} setInput={setInput} />;
  } else {
    view = (
      <SearchCandidate
        results={candidates}
        showResult={(records) => {
          setHistories(
            [input, ...(histories ?? []).filter((h) => h !== input)].slice(
              0,
              SEARCH_HISTORY_LIMIT
            )
          );
          setShowingResults(records);
        }}
      />
    );
  }

  return (
    <ErrorBoundary>
      <header className="page-header fixed-top">
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-grow-1">
            <input
              className="form-control"
              type="text"
              placeholder="氏名、車名またはナンバーで検索"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button
              className="btn pr-0"
              disabled={input === ""}
              onClick={() => setInput("")}
            >
              リセット
            </button>
          </div>
        </div>
      </header>
      {view}
    </ErrorBoundary>
  );
};

interface SearchHistoryProps {
  histories: string[];
  setInput: (input: string) => void;
}

// ヘッダーTextフィールドが未入力場合の履歴表示
const SearchHistory: React.VFC<SearchHistoryProps> = (
  props: SearchHistoryProps
) => {
  const { histories, setInput } = props;
  return (
    <main className="main">
      <header className="main-header container">
        <div className="d-flex align-items-end justify-content-between font-sm line-height-sm">
          <span className="font-sm">検索履歴</span>
        </div>
      </header>
      <div className="wrapper bg-white">
        <div className="main-body">
          <div className="container">
            <ul className="list-group">
              {histories.map((history, i) => {
                return (
                  <li
                    className="list-group-item d-flex"
                    key={i}
                    onClick={() => setInput(history)}
                  >
                    <span>{history}</span>
                    <i className="icon icon-sm icon-arrow-left-up ml-auto"></i>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};

interface SearchCandidateProps {
  results: { [name: string]: Record[] };
  showResult: (records: Record[]) => void;
}

// 文字列をヘッダーのTextフィールドに入力中のときのコンポーネント
const SearchCandidate: React.VFC<SearchCandidateProps> = (
  props: SearchCandidateProps
) => {
  const { results, showResult } = props;
  return (
    <main className="main">
      <header className="main-header container">
        <div className="d-flex align-items-end justify-content-between font-sm line-height-sm">
          <span className="font-sm">検索候補</span>
        </div>
      </header>
      <div className="wrapper bg-white">
        <div className="main-body">
          <div className="container">
            <ul className="list-group">
              {Object.entries(results).map(([name, records]) => {
                return (
                  <li
                    className="list-group-item d-flex"
                    key={name}
                    onClick={() => showResult(records)}
                  >
                    <span>{name}</span>
                    <i className="icon icon-sm icon-arrow-left-up ml-auto"></i>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};

interface SearchResultProps {
  records: Record[];
  storeStaffDomainPath: string;
}

/**
 * exhibited_car_helper.rb 参照
 */
function badgeColorFor(car: Car): string {
  if (car.trialPublishing) {
    return "warning";
  }
  switch (car.status) {
    case 0:
      return "primary";
    case 300:
      return car.verified ? "info" : "default";
    case 400:
      return "success";
    default:
      return "secondary";
  }
}

// 検索結果表示用
type RecordsPerUser = { [userId: string]: { user: User; records: Record[] } };

// 検索候補を選択して検索結果を表示するときのコンポーネント
// 会員一覧画面と同等なので持ってくる
const SearchResult: React.VFC<SearchResultProps> = (
  props: SearchResultProps
) => {
  const { records, storeStaffDomainPath } = props;
  // 検索結果表示はuser単位
  const recordsPerUser = useMemo<RecordsPerUser>(() => {
    const group: RecordsPerUser = {};
    records.forEach((record) => {
      if (group[record.user.id]) {
        group[record.user.id].records.push(record);
      } else {
        group[record.user.id] = { user: record.user, records: [record] };
      }
    });
    return group;
  }, [records]);

  return (
    <>
      <main className="main">
        <header className="main-header container">
          <div className="d-flex align-items-end justify-content-between font-sm line-height-sm">
            <span className="font-sm">
              検索結果{" "}
              <strong>
                <span className="font-base">
                  {Object.keys(recordsPerUser).length}人
                </span>
              </strong>{" "}
              を表示
            </span>
          </div>
        </header>
        <div className="wrapper bg-white">
          <div className="main-body">
            <div className="container">
              <ul className="list-group">
                {Object.values(recordsPerUser)
                  .sort((a, b) => (a.user.nameKana < b.user.nameKana ? -1 : 1))
                  .map(({ user, records }) => {
                    return (
                      <li className="list-group-item" key={user.id}>
                        <a
                          className={"notification-ref list-group-item-link"}
                          href={`${storeStaffDomainPath}/users/${user.id}?tab=profile`}
                        >
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="list-group-user">
                              <h6>
                                {user.name}
                                <span className="font-normal">
                                  （{user.nameKana}）
                                </span>
                              </h6>
                              <div className="badge-list">
                                {records.map((record) => {
                                  return (
                                    <label
                                      className="badge badge-outline"
                                      key={record.car.id}
                                    >
                                      <span
                                        className={`bg-${badgeColorFor(
                                          record.car
                                        )}`}
                                      >
                                        {record.car.statusLabel}
                                      </span>
                                      {record.car.name}
                                    </label>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoreStaffUserSearch;
