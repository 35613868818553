import { Controller } from "@hotwired/stimulus";

/**
 * テキストをクリップボードにコピーするだけ
 * UI へのフィードバックはかなりテキトー, 利用シーンが増えてから考えるので良さそう
 * GitHub は tooltip 出したりしてるが staff だと tooltip 崩れるのでやめた
 *
 * <button class="btn btn-outline-primary" type="button"
 *   data-controller="clipboard-writer" data-action="clipboard-writer#copy"
 *   data-clipboard-writer-text-value="text value to copy">
 *   <i class="fas fa-check-circle text-success" data-clipboard-writer-target="feedback"></i>
 *   URLをコピー
 * </button>
 */
export default class extends Controller {
  static values = {
    text: String,
  };
  static targets = ["feedback"];

  initialize() {
    this.#setFeedbackActive(false);
  }

  copy() {
    navigator.clipboard.writeText(this.textValue).then(() => {
      this.element.disabled = true;
      this.#setFeedbackActive(true);

      setTimeout(() => {
        this.element.disabled = false;
        this.#setFeedbackActive(false);
      }, 1000);
    });
  }

  #setFeedbackActive(active) {
    if (!this.hasFeedbackTarget) return;

    const display = active ? "" : "none";
    this.feedbackTarget.style.display = display;
  }
}
