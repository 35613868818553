import { Controller } from "@hotwired/stimulus";
// https://kenwheeler.github.io/slick/
import "slick-carousel";

/**
 * ```
 * <div class="slick-slider" data-controller="slick-carousel">
 *   <div data-slick-carousel-target="item">
 *     ここにリンク
 *   </div>
 * </div>
 * ```
 */
export default class extends Controller {
  static targets = ["item"];

  connect() {
    // スライドの枚数-1
    const slidesToShow = this.itemTargets.length - 1;

    $(this.element).slick({
      dots: true,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      centerMode: true,
      centerPadding: "0",
      slidesToShow: slidesToShow,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    });
  }
}
