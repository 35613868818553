import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="exhibited-car-conditions-submit"
 *
 * 送信ボタン
 *   data-action="exhibited-car-conditions-submit#submit"
 * チェックボックス
 *   data-exhibited-car-conditions-submit-target="toggle"
 * モーダル要素
 *   data-exhibited-car-conditions-submit-restored-target="modal"
 * 入庫データ取得(Rails)
 *   data-exhibited-car-conditions-submit-restored-value="restored"
 */
export default class extends Controller {
  static targets = ["toggle", "modal"];
  static values = {
    restored: Boolean,
  };

  submit(event) {
    if (this.restoredValue && !this.toggleTarget.checked) {
      this.#confirm(event);
    }
  }

  #confirm(event) {
    event.preventDefault();
    $(this.modalTarget).modal("show");
  }
}
