import { Controller } from "@hotwired/stimulus";

/**
 * turbo な form の submit ボタンに data-coreui-dismiss="modal" だと
 * タイミング、環境によってバックドロップが残るケースがある(staging)対策
 * input form="#id" などでモーダルを turbo-frame の外に置くのでもいいかもしれない
 *
 * data-controller="dismiss-modal-backdrop-on-disconnect"
 */
export default class extends Controller {
  disconnect() {
    document.querySelectorAll(".modal-backdrop").forEach((el) => {
      el.remove();
    });
  }
}
