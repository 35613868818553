import React, { ReactNode, useEffect, useRef } from "react";
import PinchZoom from "pinch-zoom-js";

interface Props {
  onClose?: () => void;
  enableZoom?: boolean;
  children: ReactNode;
}

const ImageMessageModal: React.VFC<Props> = (props: Props) => {
  const { onClose, enableZoom, children } = props;
  const pinchZoomRef = useRef<HTMLDivElement | null>(null);

  // PinchZoom の初期化
  useEffect(() => {
    const initializePinchZoom = (): void => {
      if (pinchZoomRef.current) {
        new PinchZoom(pinchZoomRef.current, {});
      }
    };

    if (enableZoom) {
      // 画像が読み込まれたら PinchZoom を初期化する
      requestAnimationFrame(initializePinchZoom);
    }
  }, [enableZoom]);

  return (
    <div
      className="modal fade show"
      id="alertModal"
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      style={{
        display: "block",
      }}
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div
          className="modal-content"
          style={{
            borderRadius: 0,
            overflowY: "auto",
          }}
          ref={pinchZoomRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ImageMessageModal;
