import { Controller } from "@hotwired/stimulus";

/**
 * 添付ファイル一覧表示の一覧とサムネイル表示を切り替える
 *
 * 使用例:
 * ```
 * <div data-controller="attachment-list-view">
 *   <input type="radio" name="attachment" value="thumb" data-action="change->attachment-list-view#viewChange">
 *   <input type="radio" name="attachment" value="list" data-action="change->attachment-list-view#viewChange">
 *   <ol data-attachment-list-view-target="list">
 *    ...
 *   </ol>
 * </div>
 */
export default class extends Controller {
  static targets = ["list"];

  /**
   * 添付ファイルのリスト表示を切り替える
   * @param {*} event
   */
  viewChange(event) {
    if (event.currentTarget.value == "thumb") {
      this.listTarget.classList.add("attachment-thumb");
      this.listTarget.classList.remove("attachment-list");
    } else {
      this.listTarget.classList.add("attachment-list");
      this.listTarget.classList.remove("attachment-thumb");
    }
  }
}
