import { Media, Message, Participant } from "@twilio/conversations";
import { pad } from "components/chatFunctions";
import { useImageMessageContext } from "components/ImageMessageContext";
import prettyBytes from "pretty-bytes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Menu, Item, useContextMenu } from "react-contexify";
import { WebShareNavigator } from "shared/WebShareNavigator";
import { getConversationMessageHtml } from "./getConversationMessageHtml";
import { useAsyncCatchError } from "./useAsyncCatchError";
import { ContextMenuHandler } from "../shared/ContextMenuHandler";

/* eslint-disable @typescript-eslint/no-var-requires */
const pdfIcon = require("../images/icon-pdf.svg");
const deleteIcon = require("../images/btn-delete.svg");
/* eslint-enable @typescript-eslint/no-var-requires */

interface Props {
  msg: Message;
  deleted: boolean;
  type: "give" | "receive";
  enableShare: boolean;
  name: string;
  contextMenuHandler: ContextMenuHandler;
}

interface ChatMessageProps {
  msg: Message;
  type: "give" | "receive";
  participants: Participant[];
  enableShare: boolean;
  name: string;
}

interface MsgAttributes {
  deleted?: boolean;
  authorName?: string;
  filename?: string;
  metadata?: {
    width: number;
    height: number;
  };
}

const LOAD_ERROR_MESSAGE = "メディアの取得に失敗しました";
const DELETE_MESSAGE = "メッセージを取り消しました";

export const ChatMessage: React.FC<ChatMessageProps> = (
  props: ChatMessageProps
) => {
  const {
    msg,
    type,
    participants,
    enableShare: enableShareProps,
    name,
  } = props;
  // openUrl があったら share を使えるようにする。
  const enableShare = enableShareProps || window.MobilicoApp?.openUrl != null;

  const [showModal, setShowModal] = useState<boolean>(false);

  const attributes: MsgAttributes = msg.attributes;
  const [deleted, setDelete] = useState<boolean>(
    attributes.deleted ? true : false
  );

  const menuId = "message-menu-" + msg.sid;
  const { show } = useContextMenu({ id: menuId });
  const contextMenuHandler = new ContextMenuHandler((callback) => {
    // min-width が 200 ぽいので +1rem くらい
    const x =
      screen.width < callback.posX + 216 ? screen.width - 216 : callback.posX;
    show(callback.trigger, {
      position: {
        x: x,
        y: callback.posY + 24,
      },
    });
  });

  useEffect(() => {
    // メッセージ削除をリアルタイムに反映する
    msg.addListener("updated", (data) => {
      const attributes: MsgAttributes = data.message.attributes;

      // 更新後のメッセージに削除フラグがある場合は削除扱いにする
      if (attributes.deleted) {
        setDelete(true);
      }
    });
  }, []);

  // メッセージの削除
  const deleteMessage = async (): Promise<void> => {
    setDelete(true);

    // リアルタイム反映をさせる為にattributesを更新する
    const newAttributes: MsgAttributes = {
      ...attributes,
      deleted: true,
      authorName: name,
    };
    const m = await msg.updateAttributes(newAttributes);

    // 更新したら物理削除しておく
    // TODO: 現状は一旦物理削除にしている
    m.remove();

    setShowModal(false);
  };

  // authorがsystemの場合はシステムメッセージを表示する
  // TODO: スタイルは仮
  if (msg.author === "system") {
    return (
      <div className="d-flex flex-column align-items-center mb-3">
        <div key={msg.sid} className="message-body system">
          {msg.body}
        </div>
        <div className="created-time text-nowrap">
          {pad(msg.dateCreated.getHours())}:{pad(msg.dateCreated.getMinutes())}
        </div>
      </div>
    );
  }

  return type === "give" ? (
    <div className="d-flex flex-row-reverse mb-3">
      <DeleteConfirmModal
        show={showModal}
        onSubmit={deleteMessage}
        onCancel={() => {
          setShowModal(false);
        }}
      />
      <MessageMenu
        msg={msg}
        deleted={deleted}
        onClickDelete={() => {
          setShowModal(true);
        }}
      />
      <Body
        key={msg.sid}
        msg={msg}
        deleted={deleted}
        type={type}
        enableShare={enableShare}
        name={name}
        contextMenuHandler={contextMenuHandler}
      />
      <div
        className="created-time-from align-self-end text-nowrap"
        onClick={(e) => {
          if (!deleted) {
            show(e);
          }
        }}
      >
        {participants.some((participant) => {
          if (
            participant.identity === msg.author ||
            participant.lastReadMessageIndex === null
          ) {
            return false;
          }
          return participant.lastReadMessageIndex >= msg.index;
        }) ? (
          <div className="text-right">既読</div>
        ) : (
          <div>&nbsp;</div>
        )}
        {pad(msg.dateCreated.getHours())}:{pad(msg.dateCreated.getMinutes())}
      </div>
    </div>
  ) : (
    <div className="d-flex justify-content-start mb-3">
      <Body
        key={msg.sid}
        msg={msg}
        deleted={deleted}
        type={type}
        enableShare={enableShare}
        name={name}
        contextMenuHandler={contextMenuHandler}
      />
      <div className="created-time align-self-end text-nowrap">
        {pad(msg.dateCreated.getHours())}:{pad(msg.dateCreated.getMinutes())}
      </div>
    </div>
  );
};

// fully-supported な拡張子 + PDF
// 今後増えてくるなら mime-types とかで判別する
// https://www.twilio.com/docs/sms/accepted-mime-types#supported-mime-types
const getSupportedExtension = (contentType: string): string | undefined => {
  switch (contentType) {
    case "image/jpeg":
    case "image/jpg":
      return ".jpg";
    case "image/png":
      return ".png";
    case "image/gif":
      return ".gif";
    case "application/pdf":
      return ".pdf";
  }
};

const isImageContentType = (contentType: string): boolean => {
  switch (contentType) {
    case "image/jpeg":
    case "image/jpg":
    case "image/png":
    case "image/gif":
      return true;
    default:
      return false;
  }
};

const isPdfContentType = (contentType: string): boolean => {
  return contentType === "application/pdf";
};

const Body: React.FC<Props> = (props: Props) => {
  const { msg, type, deleted, name, contextMenuHandler } = props;

  // 削除実行時または削除済みの場合、削除した旨のメッセージのみ表示する
  if (deleted) {
    const attributes: MsgAttributes = msg.attributes;
    const authorName =
      attributes.authorName !== undefined ? attributes.authorName : name;
    return (
      <div key={msg.sid} className={`message-body mx-3 ${type}`}>
        {authorName}が{DELETE_MESSAGE}
      </div>
    );
  }

  // テキスト (添付なし)
  if (msg.type === "text") {
    return (
      <MessageMenuContext
        active={type === "give"}
        contextMenuHandler={contextMenuHandler}
      >
        <div
          key={msg.sid}
          className={`message-body mx-3 ${type}`}
          dangerouslySetInnerHTML={getConversationMessageHtml(msg.body)}
        />
      </MessageMenuContext>
    );
  }

  // 1 つしか入っていないはず。
  const media = msg.attachedMedia?.[0];
  const attributes: MsgAttributes = msg.attributes;

  // 画像
  if (media && isImageContentType(media.contentType)) {
    return (
      <MessageMenuContext
        active={type === "give"}
        contextMenuHandler={contextMenuHandler}
      >
        <ImageBody {...props}></ImageBody>
      </MessageMenuContext>
    );
  }

  // PDF
  if (media && isPdfContentType(media.contentType)) {
    const filename = attributes.filename || media.filename;

    return (
      <MessageMenuContext
        active={type === "give"}
        contextMenuHandler={contextMenuHandler}
      >
        <PdfMedia
          media={media}
          filename={filename || "添付資料.pdf"}
          size={media.size}
        />
      </MessageMenuContext>
    );
  }

  // 対応していないメディアだった。
  return <div>{LOAD_ERROR_MESSAGE}</div>;
};

const ImageBody: React.FC<Props> = (props: Props) => {
  const { msg, enableShare } = props;
  const [url, setUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<boolean>(false);
  const runAsync = useAsyncCatchError();

  // 1 つしか入っていないはず。
  const media = msg.attachedMedia?.[0];
  const attributes: MsgAttributes = msg.attributes;

  useEffect(() => {
    runAsync(async () => {
      if (!media) {
        console.error("media がない！");
        setError(true);
        return;
      }

      try {
        const mediaUrl = await media.getContentTemporaryUrl();
        const response = await fetch(mediaUrl);
        const blob = await response.blob();
        const ext = getSupportedExtension(blob.type);
        if (ext) {
          const f = new File([blob], media.sid + ext, {
            type: blob.type,
          });
          setFile(f);
        }
        setUrl(URL.createObjectURL(blob));
      } catch (error) {
        console.error(error);
        setError(true);
      }
    });
  }, []);

  const openTemporaryUrl = useCallback(() => {
    runAsync(async () => {
      if (!media) {
        console.error("media がない！");
        setError(true);
        return;
      }

      const openUrl = window.MobilicoApp?.openUrl;
      if (!openUrl) {
        console.error("openUrl がない！");
        setError(true);
        return;
      }

      const mediaUrl = await media.getContentTemporaryUrl();
      openUrl(mediaUrl);
    });
  }, []);

  if (error) {
    return <div>{LOAD_ERROR_MESSAGE}</div>;
  }

  return (
    <>
      <ImageMedia url={url} metadata={attributes.metadata} />
      {enableShare ? (
        <Sharing file={file} openTemporaryUrl={openTemporaryUrl} />
      ) : undefined}
    </>
  );
};

interface ImageMediaProps {
  url: string | null;
  metadata: MsgAttributes["metadata"];
}

const ImageMedia: React.FC<ImageMediaProps> = (props: ImageMediaProps) => {
  const IMG_SIZE = 240;
  const { url, metadata } = props;
  const { setUrl } = useImageMessageContext();

  const style = useMemo(() => {
    const [width, height] = metadata
      ? [IMG_SIZE, (IMG_SIZE * metadata.height) / metadata.width]
      : [IMG_SIZE, IMG_SIZE];

    return {
      box: {
        width,
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      mediaMessage: {
        maxWidth: width,
        maxHeight: height,
        borderRadius: "4px",
      },
    };
  }, [url]);

  return (
    <div className="mx-3" style={style.box}>
      {!url && (
        <div style={{ ...style.box, backgroundColor: "#eee" }}>
          <div>
            <i className="fa fa-5x fa-spinner fa-spin" />
          </div>
        </div>
      )}

      {url && (
        <img
          src={url}
          style={style.mediaMessage}
          onClick={() => {
            setUrl(url);
          }}
        />
      )}
    </div>
  );
};

interface PdfMediaProps {
  filename: string;
  size: number;
  media: Media | undefined;
}

// ポップアップブロックを回避して PDF を開くためのモーダルを表示する。
const openShowPdfModal = async (
  filename: string,
  getUrl: () => Promise<string>
): Promise<void> => {
  const modal = document.getElementById("show_pdf_modal");
  const modalBody = document.getElementById("show_pdf_modal_body");
  const modalButton = document.getElementById("show_pdf_modal_button");
  if (
    !(
      modal instanceof HTMLDivElement &&
      modalBody instanceof HTMLDivElement &&
      modalButton instanceof HTMLButtonElement
    )
  ) {
    console.error("show_pdf_modal がない!");
    return;
  }

  // URL 取得前に中身を消してモーダルを開いておく。
  modalBody.textContent = "";
  modalButton.classList.add("disabled");
  modalButton.onclick = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const jQuery = (window as any).jQuery as any;
  jQuery(modal).modal("show");

  const mediaUrl = await getUrl();

  modalBody.textContent = `"${filename}" を開きますか？`;
  modalButton.classList.remove("disabled");
  modalButton.onclick = () => {
    // ポップアップブロックされないように、 onclick で直接 window.open する。
    window.open(mediaUrl, "_blank");
  };
};

const PdfMedia: React.FC<PdfMediaProps> = (props: PdfMediaProps) => {
  const { filename, size, media } = props;
  const runAsync = useAsyncCatchError();

  const onClick = useCallback(() => {
    if (!media) {
      return;
    }

    runAsync(async () => {
      const openUrl = window.MobilicoApp?.openUrl;
      if (openUrl) {
        const mediaUrl = await media.getContentTemporaryUrl();
        openUrl(mediaUrl);
      } else {
        openShowPdfModal(filename, () => media.getContentTemporaryUrl());
      }
    });
  }, [media]);

  return (
    <button
      onClick={onClick}
      className="btn btn-light text-left bg-light p-3 rounded mx-3 flex-grow-1"
    >
      <span className="d-flex">
        <img src={pdfIcon} width={48} />
        <span className="ml-2">
          {filename}
          <br />
          サイズ：{prettyBytes(size)}
        </span>
      </span>
    </button>
  );
};

interface ShareButtonProps {
  onClick: () => void;
}

const ShareButton: React.VFC<ShareButtonProps> = (props: ShareButtonProps) => {
  const { onClick } = props;
  return (
    <button className="btn px-0" onClick={onClick} style={{ minWidth: 0 }}>
      <i className="fas fa-file-download"></i>
    </button>
  );
};

interface SharingProps {
  file: File | undefined;
  openTemporaryUrl: () => void;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
const Sharing: React.FC<SharingProps> = (props: SharingProps) => {
  const { file, openTemporaryUrl } = props;
  const shareData = file ? { files: [file] } : undefined;

  const shareByWebShare = async (): Promise<void> => {
    try {
      await WebShareNavigator.share(shareData);
    } catch (error) {
      if (error instanceof DOMException) {
        if (error.name === "NotAllowedError") {
          console.warn(error);
          // WebView だと 2 回目以降に NotAllowedError になるバグがあるっぽい。
          // https://github.com/labox-inc/sion-web/issues/3337
          // 回避策としてページをリロードする。
          if (window.MobilicoApp?.openUrl) {
            openTemporaryUrl();
          } else {
            location.reload();
          }
          return;
        } else if (error.name === "AbortError") {
          // シェアをキャンセルした場合に来るっぽい。
          console.warn(error);
          return;
        }
      }
      throw error;
    }
  };

  // mac の chrome 等、対応していない環境で表示の確認だけしたい場合は、ここを true にする。
  const canWebShare = shareData && WebShareNavigator.canShare?.(shareData);

  if (canWebShare) {
    return <ShareButton onClick={shareByWebShare}></ShareButton>;
  }

  if (window.MobilicoApp?.openUrl) {
    return <ShareButton onClick={openTemporaryUrl}></ShareButton>;
  }

  return <></>;
};

interface MessageMenuContextProps {
  active: boolean;
  children: React.ReactNode;
  contextMenuHandler: ContextMenuHandler;
}

const MessageMenuContext: React.FC<MessageMenuContextProps> = (
  props: MessageMenuContextProps
) => {
  const { active, contextMenuHandler } = props;

  return active ? (
    <div
      onTouchStart={contextMenuHandler.onTouchStart}
      onTouchMove={contextMenuHandler.onTouchMove}
      onTouchCancel={contextMenuHandler.onTouchCancel}
      onTouchEnd={contextMenuHandler.onTouchEnd}
      onContextMenu={contextMenuHandler.onContextMenu}
    >
      {props.children}
    </div>
  ) : (
    <>{props.children}</>
  );
};

interface MessageMenuProps {
  msg: Message;
  deleted: boolean;
  onClickDelete: () => void;
}

const MessageMenu: React.FC<MessageMenuProps> = (props: MessageMenuProps) => {
  const { msg, deleted, onClickDelete } = props;

  const menuId = "message-menu-" + msg.sid;

  return !deleted ? (
    <Menu id={menuId}>
      <Item onClick={onClickDelete}>
        <img src={deleteIcon} />
        <span className="mb-0 ml-1">このメッセージを削除</span>
      </Item>
    </Menu>
  ) : null;
};

interface DeleteConfirmModalProps {
  show: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = (
  props: DeleteConfirmModalProps
) => {
  const { show, onSubmit, onCancel } = props;

  return show ? (
    <div>
      <div className="fade modal-backdrop show" />
      <div
        className="modal fade show"
        id="alertModal"
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        style={{
          display: "block",
        }}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div
            className="modal-content"
            style={{
              borderRadius: 0,
            }}
          >
            <div className="modal-header d-flex justify-content-center align-items-center">
              <span className="text-center mr-3 text-nowrap">
                <span className="h1 mb-0">メッセージを削除しますか？</span>
              </span>
            </div>
            <div className="modal-body">
              <div className="text-center">
                削除後は元に戻すことができません。
                <br />
                よろしいですか？
              </div>
            </div>
            <div className="modal-footer p-2 mx-2 d-flex justify-content-center">
              <a className="btn btn-outline-dark px-4" onClick={onCancel}>
                キャンセル
              </a>
              <a
                className="btn btn-outline-secondary px-5 ml-3"
                onClick={onSubmit}
              >
                削除
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
