import { Controller } from "@hotwired/stimulus";

/** URL のハッシュ (`#` 以降の部分) で bootstrap の tab を切り替える。
 * tab の id は `tab-${hash}` にする。同じだとスクロールしてしまった。
 *
 * tablist に `data-controller="activate-tab-by-hash"` をつける。
 */
export default class extends Controller {
  connect() {
    window.addEventListener("hashchange", this.#onhashchange);
    this.#onhashchange();
  }

  disconnect() {
    window.removeEventListener("hashchange", this.#onhashchange);
  }

  #onhashchange = () => {
    const match = location.hash.match(/^#([\w-]+)$/);
    const hash = match?.[1];
    if (!hash) {
      return;
    }

    const tabLink = this.element.querySelector(`[href="#tab-${hash}"]`);
    if (tabLink) {
      $(tabLink).tab("show");
    }
  };
}
