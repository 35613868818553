import { Controller } from "@hotwired/stimulus";

/**
 * トークルームのメッセージ送信ボタンの動作を管理する
 *   - テキストエリアに入力が無い場合は送信ボタンを非活性にする
 *   - メッセージの改行に応じてテキストエリアの高さを可変する
 *   - メッセージ送信後にテキストエリアをクリアし、送信ボタンを非活性にする
 *
 * 設定例
 * <%= form_with data: {
 *       controller: "talkroom-send-button",
 *       action: "submit->talkroom-send-button#submitAndClearTextarea"
 *       } do |f| %>
 *   <%= f.textarea data: {
 *         talkroom_send_button_target: "textarea",
 *         action: %w[
 *           input->talkroom-send-button#enableSendButton
 *           input->talkroom-send-button#textareaAutoResize
 *         ].join(" ") } %>
 *   <%= image_submit_tag "", data: { talkroom_send_button_target: "sendButton" } %>
 * <%= end %>
 */
export default class extends Controller {
  static targets = ["textarea", "sendButton", "uploadButton"];

  connect() {
    this.enableSendButton();
  }

  /**
   * チャットメッセージが入力されている場合に送信ボタンを有効にする
   */
  enableSendButton() {
    const sendMessageIcon = require("../../images/send-message.svg");
    const disableSendMessageIcon = require("../../images/disable-send-message.svg");

    if (this.textareaTarget.value) {
      this.sendButtonTarget.src = sendMessageIcon;
      this.sendButtonTarget.disabled = false;
    } else {
      this.sendButtonTarget.src = disableSendMessageIcon;
      this.sendButtonTarget.disabled = true;
    }
  }

  /**
   * テキストエリアの改行状態に応じて高さを可変する
   */
  textareaAutoResize() {
    this.textareaTarget.style.height = "auto";
    this.textareaTarget.style.height = this.textareaTarget.scrollHeight + "px";
  }

  /**
   * メッセージ送信とテキストエリアの消去
   */
  submitAndClearTextarea(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    // 重複送信しないように送信ボタンを非活性にする
    this.sendButtonTarget.disabled = true;
    this.uploadButtonTarget.disabled = true;

    fetch(event.target.action, {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.ok) {
        // メッセージ送信成功後、テキストエリアをリセットする
        this.textareaTarget.value = null;
        this.textareaAutoResize();
      } else {
        console.error("メッセージ送信エラー (" + response.status + ")");

        // 422エラーの場合はリロードする
        if (response.status == 422) {
          location.reload();
        }
      }

      this.enableSendButton();
      this.uploadButtonTarget.disabled = false;
    });
  }
}
