import { Controller } from "@hotwired/stimulus";

/**
 * connect で指定されたイベントを dispatch するだけの人, turbo-stream のレスポンスで使ってる
 * controller: :dispatch, dispatch_event_value: "badge:must-revalidate"
 */
export default class extends Controller {
  static values = {
    event: String,
  };

  connect() {
    if (!this.hasEventValue) {
      return;
    }

    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.dispatch(this.eventValue, { detail: { content: this } });
    }
  }
}
