import { Controller } from "@hotwired/stimulus";

/**
 * トークの既読同期。ユーザー・スタッフ共通。
 *
 * url に read_conversation_sid, read_message_index を付けて GET する。
 * document へのイベントを受け取るだけなので、ページ内のどこにあっても OK.
 *
 *  data: {
 *    controller: "read-conversation",
 *    read_conversation_url: url
 *  }
 */
export default class extends Controller {
  connect() {
    document.addEventListener("sion-ReadConversation", this.send, false);
  }

  disconnect() {
    document.removeEventListener("sion-ReadConversation", this.send, false);
  }

  send = (event) => {
    const conversationSid = event.detail?.sid;
    const messageIndex = event.detail?.index;
    if (
      typeof conversationSid !== "string" ||
      typeof messageIndex !== "number"
    ) {
      console.error("not ReadConversation", event);
      return;
    }

    const url = new URL(this.data.get("url"), location.href);
    url.searchParams.append("read_conversation_sid", conversationSid);
    url.searchParams.append("read_message_index", messageIndex);
    fetch(url, {
      headers: {
        Accept: "application/json",
      },
    });
  };
}
