import { Controller } from "@hotwired/stimulus";

/** bootstrap の modal を、イベントキャンセルせずに dismiss する。
 *
 * 使用例:
 * ```
 * <div class="modal" tabindex="-1" role="dialog" data-controller="modal-dismiss">
 *  <%= link_to 'モーダルを閉じつつリンク先に遷移する', リンク先, data: { action: "modal-dismiss#hide" } %>
 * </div>
 * ```
 */
export default class extends Controller {
  hide() {
    window.BootstrapWrapper.hideModal(this.element);
  }
}
