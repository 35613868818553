import { Controller } from "@hotwired/stimulus";
import autocomplete from "autocompleter";
import { autocompleteSelectboxConfig } from "../../shared/AutocompleteSelectbox";

// data: { controller: "autocomplete-selectbox" }
export default class extends Controller {
  connect() {
    this.opt = autocompleteSelectboxConfig(this.element);
    this.autocomplete = autocomplete(this.opt);
  }

  disconnect() {
    this.opt.input.remove();
    this.autocomplete.destroy();
  }
}
