import React from "react";
import { dispatchQrReaderErrorEvent } from "./events";

type Props = {
  children: React.ReactNode;
};

interface State {
  hasError: boolean;
  message: string;
}

// https://ja.reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, message: "" };
  }

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { hasError: true, message: error?.message ?? `${error}` };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo);

    // QrReader 用のエラーイベントを飛ばす。
    dispatchQrReaderErrorEvent();
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <p>エラーが発生しました。</p>
          <p>
            <small>{this.state.message}</small>
          </p>
          <button onClick={() => location.reload()} className="btn btn-primary">
            再読み込み
          </button>
        </>
      );
    }

    return this.props.children;
  }
}
