import { Controller } from "@hotwired/stimulus";

/**
 * トークルーム名を編集する
 */
export default class extends Controller {
  static targets = ["input", "submit", "form", "talkroomName"];

  connect() {
    this.#setViewMode("show");
  }

  /**
   * actionからViewモードを変更する
   * @param {PointerEvent} event
   */
  updateMode(event) {
    this.#setViewMode(event.params.mode);
  }

  /**
   * トークルーム名を更新する
   */
  async updateName() {
    this.submitTarget.disabled = true;

    const formData = new FormData(this.formTarget);

    try {
      const response = await fetch(this.formTarget.action, {
        method: "PATCH",
        body: formData,
      });
      const json = await response.json();

      if (response.ok) {
        this.talkroomNameTarget.textContent = json.talkroom_name;
        this.inputTarget.value = json.talkroom_name;
      } else {
        console.error("トークルーム名の更新失敗: ", response.status);
        console.error("レスポンス: ", json);

        alert("トークルーム名の変更に失敗しました。再度お試しください。");
      }
    } catch (error) {
      alert("エラー発生が発生しました");
      console.error("Error: ", error);
    }

    this.submitTarget.disabled = false;
    this.#setViewMode("show");
  }

  /**
   * Viewモードをセットする
   *   mode: show トークルーム名を表示する
   *   mode: edit トークルーム名を編集する
   * @param {String} mode
   */
  #setViewMode(mode) {
    this.element.dataset.talkroomNameViewMode = mode;
  }
}
