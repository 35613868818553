import { Controller } from "@hotwired/stimulus";
import loadImage from "blueimp-load-image";
import stampImage from "../../images/numberplate.svg";
import activeStampImage from "../../images/numberplate-active.svg";
import { StampCanvas } from "../../shared/stamp_canvas";
import Croppie from "croppie";

let idGenerator = 1;

/** mypage_listing_car_images/edit の各画像の form
 *
 * form の状態によって `data-controller` がついている要素の `data-form-status` の値が変わる。
 * - `data-form-status="normal"`: 通常状態
 * - `data-form-status="croppie"`: 画像クロップ。ダイアログ初期状態。
 * - `data-form-status="stamp"`: ナンバープレート隠し。ダイアログの中身が変わる。
 * - `data-form-status="preview"`: ファイルを選択して保存前のプレビュー状態。ダイアログが開いている。
 * - `data-form-status="loading"`: 主に保存ダイアログが閉じて画像のアップロード中。選択したファイルの読み込み中もこれ。
 *
 * data-controller="mypage-listing-car-images-form"
 */
export default class extends Controller {
  static values = { attached: Boolean };
  static targets = [
    "originalImage",
    "form",
    "figure",
    "previewImage",
    "croppieContainer",
    "fileInput",
    "saveModal",
    "stampCanvasParent",
    "cropInput",
    "sizeInput",
    "resetBtn",
    "label",
    "warning",
  ];

  connect() {
    /** @type {HTMLFormElement} */
    const form = this.formTarget;

    /* reg_suit 用 data-testid の設定 */
    const prefix = "label-connector-";
    const id = `${prefix}${idGenerator++}`;

    /** @type {HTMLLabelElement[]} */
    const labels = this.labelTargets;
    labels.forEach((label) => {
      label.dataset.testid = id;
    });

    /* form挙動制御`Listener`群 */
    window.addEventListener("beforeunload", (event) => {
      this.#alertUnload(event);
    });
    form.addEventListener("blob-upload-form:start", () => {
      this.#loading();
    });
    form.addEventListener("blob-upload-form:success", (event) => {
      this.#update(event);
    });
    form.addEventListener("blob-upload-form:error", (event) => {
      this.error(event);
    });

    /* 車両画像編集`Listener`群 */
    this.fileInputTarget.addEventListener("change", (_event) => {
      this.#afterFileSelect();
    });
    this.cropInputTarget.addEventListener("input", (event) => {
      this.#zoomControl(event);
    });
    $(this.saveModalTarget).on("hide.bs.modal", () => {
      this.#onHideSaveModal();
    });
    this.element.dataset.formStatus = "normal";
  }

  /* - public methods - */

  /* -- 画像切り抜き -- */

  async setCroppie() {
    const blob = await this.#getCroppieResult();
    this.currentBlob = blob;
    await this.#setBlobUrl(blob);
    this.element.dataset.formStatus = "preview";
  }

  /* -- ナンバー隠し -- */

  async stamp() {
    // `currentBlob` が存在しない場合
    if (!this.currentBlob) {
      this.currentBlob = await this.#getCroppieResult();
    }
    // 編集途中の `currentBlob` が存在する場合
    await this.#setCanvasWithStamp(this.currentBlob);
    this.element.dataset.formStatus = "stamp";
  }

  async setStamp() {
    this.stampCanvas.drawResult();
    const ctb = await canvasToBlobAsync(this.stampCanvas.canvas);
    this.currentBlob = ctb;
    await this.#setBlobUrl(ctb);
    this.element.dataset.formStatus = "preview";
  }

  async editStamp() {
    // 車両画像が登録されていない場合
    if (!this.attachedValue) {
      // ファイル選択イベント発火
      const event = new MouseEvent("click");
      this.fileInputTarget.dispatchEvent(event);
      return;
    }

    // 車両画像がすでに登録されている場合
    const blob = await urlToBlobAsync(this.originalImageTarget.src);
    await this.#setCanvasWithStamp(blob);

    $(this.saveModalTarget)
      .modal({
        backdrop: "static",
        keyboard: false,
      })
      .modal("show");
    this.element.dataset.formStatus = "stamp";
  }

  /* -- プレビュー -- */

  /**
   * 車両画像登録時処理 (ただし、実際の登録は `blob_upload_form.js`)
   */
  async save() {
    this.attachedValue = true;
    this.element.dataset.formStatus = "loading";
    $(this.saveModalTarget).modal("hide");
    if (this.hasWarningTarget) {
      this.warningTarget.classList.add("d-none");
    }
  }

  /**
   * 画像編集リセット処理
   */
  async reset() {
    if (this.file) {
      // ファイル選択されている場合、クロップに遷移
      await this.#createCroppieBlob(this.file);
      this.element.dataset.formStatus = "croppie";
    } else {
      // 登録済み車両画像を編集する場合、ナンバー隠しに遷移
      const blob = await urlToBlobAsync(this.originalImageTarget.src);
      await this.#setCanvasWithStamp(blob);
      this.element.dataset.formStatus = "stamp";
    }
    this.currentBlob = undefined;
  }

  /* -- 一覧 -- */

  destroy(event) {
    const [response, ,] = event.detail;
    this.originalImageTarget.src = response.url;
    this.attachedValue = false;
    this.element.classList.remove("saved");
  }

  error(event) {
    alert(`エラーが発生しました: ${event.detail}`);

    // バリデーションに引っかかった場合は 200 を返して update に行くので、
    // それ以外のなにかエラーが起きた。リロードしておく。
    location.reload();
  }

  /* - private methods - */

  /* -- 車両画像編集関連機能 -- */

  /**
   * 画像ファイル選択時処理
   */
  async #afterFileSelect() {
    const fileInput = this.fileInputTarget;
    if (fileInput.files.length < 1) {
      return;
    }
    this.file = fileInput.files[0];
    this.element.dataset.formStatus = "loading";

    // 同じファイルを選択した時にまた change イベントが来るように、ファイルの選択を解除する。
    fileInput.value = "";

    // 画像を読み込んでから modal を開く
    $(this.saveModalTarget)
      .modal({
        backdrop: "static",
        keyboard: false,
      })
      .modal("show");

    // croppie に file を読み込み、4:3の画像 blob を生成
    await this.#createCroppieBlob(this.file);
    this.element.dataset.formStatus = "croppie";
  }

  /**
   * croppie のカスタム Zoom スライダーイベント処理
   * @param {Event} event
   */
  #zoomControl(event) {
    const val = parseFloat(event.target.value);
    this.croppie.setZoom(this.minZoom + val);
  }

  /**
   * 任意の blob を croppie によってクロップされたバイナリに変換する
   * @param {Blob} blob
   */
  async #createCroppieBlob(blob) {
    // 既存 croppie 削除
    if (this.croppie) {
      this.croppie.destroy();
      this.croppie = undefined;
    }
    const img = new Image();
    const blobUrl = URL.createObjectURL(blob);
    this.fileInputTarget.dataset.src = blobUrl;
    img.src = blobUrl;

    img.onload = () => {
      // croppie 生成
      const isLandscape = img.width >= (img.height * 4) / 3;
      this.element.dataset.previewImageOrientation = isLandscape
        ? "landscape"
        : "portrait";

      const vh = isLandscape
        ? this.figureTarget.clientHeight
        : (this.figureTarget.clientWidth * 3) / 4;
      const vw = isLandscape
        ? (this.figureTarget.clientHeight * 4) / 3
        : this.figureTarget.clientWidth;
      this.minZoom = isLandscape ? vh / img.height : vw / img.width;
      this.croppieContainerTarget.dataset.blobUrl = blobUrl;

      this.croppie = new Croppie(this.croppieContainerTarget, {
        viewport: {
          width: vw,
          height: vh,
          type: "square",
        },
        showZoomer: false,
        mouseWheelZoom: false,
        boundary: {
          height: this.figureTarget.clientHeight,
        },
        minZoom: this.minZoom,
        maxZoom: this.minZoom + 1.5,
      });

      this.croppie.bind({ url: blobUrl, zoom: 0 });
    };
  }

  /**
   * form の送信用の blobUrl を設定する
   * @param {Blob} blob
   */
  async #setBlobUrl(blob) {
    const blobUrl = URL.createObjectURL(blob);
    this.previewImageTarget.src = blobUrl;
    const fileInput = this.fileInputTarget;
    if (fileInput.dataset.blobUrl) {
      URL.revokeObjectURL(fileInput.dataset.blobUrl);
    }
    fileInput.dataset.blobUrl = blobUrl;
  }

  /**
   * クロップ画像から車両ナンバーを隠した画像を生成する
   * @param {Blob} blob
   */
  async #setCanvasWithStamp(blob) {
    const canvasWidth = this.element.dataset.imageMaxWidth;
    const canvasHeight = this.element.dataset.imageMaxHeight;

    const { image: canvas } = await loadImage(blob, {
      maxWidth: canvasWidth,
      maxHeight: canvasHeight,
      canvas: true,
    });
    const stamp = await createImage(stampImage);
    const uiStamp = await createImage(activeStampImage);

    // ナンバープレートのサイズは 110x55 だけど、斜め向きだと高さが狭すぎるので大きめにする。
    this.stampCanvas = new StampCanvas(
      canvas,
      canvasWidth,
      canvasHeight,
      stamp,
      uiStamp,
      165,
      105
    );
    this.stampCanvas.stampX = canvasWidth / 2;
    this.stampCanvas.stampY = canvasHeight / 2;
    this.stampCanvas.drawUi();

    // sizeInput と stampCanvas をつなげる。
    const sizeInput = this.sizeInputTarget;
    sizeInput.value = this.stampCanvas.stampScale;
    sizeInput.addEventListener("input", () => {
      this.stampCanvas.stampScale = parseFloat(sizeInput.value);
      this.stampCanvas.drawUi();
    });
    this.stampCanvas.onChangeScale = (scale) => {
      sizeInput.value = scale;
    };

    // 中身を置き換える。
    while (this.stampCanvasParentTarget.firstChild) {
      this.stampCanvasParentTarget.removeChild(
        this.stampCanvasParentTarget.firstChild
      );
    }
    this.stampCanvasParentTarget.append(this.stampCanvas.canvas);
  }

  /**
   * クロップ画像の結果を取得する
   * @returns {Blob}
   */
  async #getCroppieResult() {
    if (!this.croppie) return null;
    const width = this.element.dataset.imageMaxWidth;
    const height = this.element.dataset.imageMaxHeight;
    return await this.croppie.result({
      type: "blob",
      size: { width, height },
    });
  }

  #onHideSaveModal() {
    /** @type {HTMLFormElement} */
    const form = this.formTarget;

    form.reset();
    if (this.croppie) {
      this.croppie.destroy();
      this.croppie = undefined;
    }
    this.currentBlob = undefined;
    this.element.dataset.formStatus = "normal";
    this.element.dataset.previewImageOrientation = "";
  }

  /* -- form挙動制御関連 -- */

  #alertUnload(event) {
    // preview or 通信状態で画面遷移しそうになったら止める。
    if (["loading", "preview"].includes(this.element.dataset.formStatus)) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  #loading() {
    this.element.dataset.formStatus = "loading";
  }

  #update(event) {
    const [response, ,] = event.detail;

    if (response.errors) {
      this.element.dataset.formStatus = "preview";
      alert("画像を保存できませんでした。\n" + response.errors.join("\n"));
      return;
    }
    this.element.dataset.formStatus = "normal";
    this.element.classList.add("saved");

    this.originalImageTarget.src = response.url;

    const form = event.target;
    form.reset();
    // 何故か disabled になっていたので戻す。
    Array.from(form.elements).forEach((x) => {
      x.disabled = false;
    });
  }
}

/* - utilities function - */

/**
 * @param {HTMLCanvasElement} canvas
 * @param {String} mimeType
 * @param {Number} [qualityArgument]
 * @returns {Promise<Blob>}
 */
function canvasToBlobAsync(canvas, mimeType, qualityArgument) {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), mimeType, qualityArgument);
  });
}

/**
 * @returns {Promise<HTMLImageElement>}
 */
function createImage(src) {
  const image = new Image();
  return new Promise((resolve, _reject) => {
    image.addEventListener("load", () => {
      resolve(image);
    });
    image.src = src;
  });
}

/**
 * @param {String} url
 * @returns {Promise<Blob>}
 */
async function urlToBlobAsync(url) {
  const res = await fetch(url);
  return await res.blob();
}
