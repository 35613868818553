import { Controller } from "@hotwired/stimulus";

/**
 * recaptcha で認証できたら送信ボタンを有効化する。
 * data-controller="recaptcha"
 * 送信ボタン: data-contact-target="submit"
 */
export default class extends Controller {
  static targets = ["submit", "recaptcha"];

  /**
   * @returns {HTMLButtonElement}
   */
  #submitButton() {
    return this.submitTarget;
  }

  connect() {
    if (this.hasRecaptchaTarget) {
      this.#setUpRecaptcha();
    }
  }

  #setUpRecaptcha() {
    // 最初は disabled にしておく。
    this.#submitButton().disabled = true;

    // 認証できた場合はフォームの disabled を削除する。
    window.captured = (token) => {
      if (token.length > 0) {
        this.#submitButton().disabled = false;
      }
    };

    // 時間切れになったら disabled に戻す。
    window.expired = () => {
      this.#submitButton().disabled = true;
    };
  }
}
