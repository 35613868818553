import { Controller } from "@hotwired/stimulus";
import preventScroll from "../../shared/prevent_scrolling";

/**
 * QR リーダーでエラー表示。
 *
 * a href="エラー時の遷移先" data-controller="qr-reader-error"
 */
export default class extends Controller {
  connect() {
    // スクロールを禁止する。
    if (window.screen.availHeight >= 474) {
      preventScroll();
    }

    const backUrl = this.element.href;
    $("#deviceLoadErrorModal").on("hide.bs.modal", () => {
      location.href = backUrl;
    });
    $("#generalQrReaderErrorModal").on("hide.bs.modal", () => {
      location.href = backUrl;
    });

    document.addEventListener(
      "sion-QrReaderError",
      (error) => {
        if (error.detail?.videoInputDeviceNotFound) {
          $("#deviceLoadErrorModal").modal("show");
          return;
        }

        if (error.detail?.message) {
          $("#generalQrReaderErrorModalBody").text(error.detail.message);
        }
        $("#generalQrReaderErrorModal").modal("show");
      },
      false
    );
  }
}
