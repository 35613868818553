/**
 * 現在のURLを iframe で開かれているかのパラメーターを付与して返す
 * @returns パラメーター付与済みの場合は null
 */
export default function getCurrentUrlAppendingIframeParam(): URL | null {
  const url = new URL(location.href);

  if (url.searchParams.has("iframe")) return null;

  const iframe = window !== window.parent;
  url.searchParams.set("iframe", iframe.toString());
  return url;
}
