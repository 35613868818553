import { NumberPlate } from "./types";

// rails 側で扱いやすいように null じゃなくて中身空のオブジェクト返す
const emptyNumber: NumberPlate = {
  locale: "",
  classNumber: "",
  hiragana: "",
  number: "",
};

export function parseNumberPlate(string: string): NumberPlate {
  // eslint-disable-next-line
  // 車検証そのままだと `横浜　　３０５す４５００` みたいなのが来るので normalize で半角数字などに置き換える
  const normalized = string.normalize("NFKC");
  const match = normalized.match(/([^\d\s]+)\s*(\d+)\s*([ぁ-ん])\s*(\d+)/);
  if (!match) {
    return emptyNumber;
  }

  const locale = match[1];
  const classNumber = match[2];
  const hiragana = match[3];
  const number = match[4];
  return { locale, classNumber, hiragana, number };
}
