/**
 * Web Share API を呼び出す。
 * TODO: TypeScript アップデートして DOM の type が対応したら navigator をそのまま使う。
 */

// https://github.com/microsoft/TypeScript-DOM-lib-generator/blob/c1678fb06ca5df1a135ba5f52dbb6800a56e79b4/baselines/dom.generated.d.ts#L1575-L1580
interface ShareData {
  files?: File[];
  text?: string;
  title?: string;
  url?: string;
}

// Navigator のうち、必要なものだけ。
// https://github.com/microsoft/TypeScript-DOM-lib-generator/blob/c1678fb06ca5df1a135ba5f52dbb6800a56e79b4/baselines/dom.generated.d.ts#L9586-L9611
interface WebShareNavigator {
  canShare?(data?: ShareData): boolean;
  share(data?: ShareData): Promise<void>;
}

const getNavigator = (): WebShareNavigator => {
  // TODO: TypeScript アップデートして DOM の type が対応したら navigator をそのまま使う。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return navigator as any as WebShareNavigator;
};

export const WebShareNavigator: WebShareNavigator = {
  canShare: (data?: ShareData): boolean => {
    // canShare がない場合 (undefined) も false
    return getNavigator().canShare?.(data) || false;
  },
  share: (data?: ShareData): Promise<void> => {
    return getNavigator().share(data);
  },
};
