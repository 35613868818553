import { Controller } from "@hotwired/stimulus";

/**
 * ロード時にフェードインするだけ, bootstrap の .fade.show を利用
 * lazy な turbo-frame で使いたかった
 * 頑張れば CSS だけでも同じことは実現できそうな気がする
 *
 * usage: data-controller="fade"
 */
export default class extends Controller {
  connect() {
    this.element.classList.add("fade");
    setTimeout(() => {
      this.element.classList.add("show");
    }, 75);
  }
}
