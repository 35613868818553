import { Controller } from "@hotwired/stimulus";

// memo: stimulus から dispatch したいので controller:scope:action 的にした
const EVENT_BADGE_MUST_REVALIDATE = "dispatch:badge:must-revalidate";

/**
 * バッジ更新通知を受け取ったら様子を見に行く人
 * data-controller="badge"
 * data-badge-url-value="<%= unreads_store_staff_talkrooms_path(format: :json) %>"
 */
export default class extends Controller {
  static values = {
    url: String,
  };

  #unreadCounts = [];

  connect() {
    if (this.hasUrlValue) {
      document.addEventListener(EVENT_BADGE_MUST_REVALIDATE, this.#revalidate);
      if (!document.documentElement.hasAttribute("data-turbo-preview")) {
        this.#fetchUnreads();
      }
    }
  }

  disconnect() {
    document.removeEventListener(EVENT_BADGE_MUST_REVALIDATE, this.#revalidate);
  }

  #revalidate = ({
    detail: {
      content: { context },
    },
  }) => {
    if (this.#unreadCounts[context.element.id]) {
      // 記録した未読数がある場合は fetch 前に直前の数を入れておく
      // 未読バッジはブロードキャストされるパーシャルに含められない(誰が受信するか分からない)ため、受信時には一旦空になってしまう
      // 未読 1 → パーシャル受信で 0 → 更新で 2 などでチラつきが発生する
      // ここで未読数の加算をやると連続受信などでズレが発生する場合があるので更新はしない
      const el = document.getElementById(`${context.element.id}_unreads`);
      if (el) {
        this.#updateBadgeCount(el, this.#unreadCounts[context.element.id]);
      }
    }
    this.#fetchUnreads();
  };

  #fetchUnreads() {
    fetch(this.urlValue, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        let total = 0;
        // 0 のものは返してないので別タブとかで 0 になるとかは考慮しない
        for (const key in json) {
          total += json[key];
          const el = document.getElementById(`talkroom_${key}_unreads`);
          if (el) {
            this.#updateBadgeCount(el, json[key]);
          }

          // 取得した未読数を記録しておく @see #revalidate
          this.#unreadCounts[`talkroom_${key}`] = json[key];
        }
        if (total > 0) {
          this.#updateBadgeCount(this.element, total);
        }
      });
  }

  #updateBadgeCount(el, count) {
    if (el.textContent != `${count}`) {
      el.textContent = count;
    }
  }
}
