import { Controller } from "@hotwired/stimulus";

/**
 * 直接指定の出品モーダルを表示する用
 */
export default class extends Controller {
  static targets = [
    "selectPublishingTypeContent",
    "trialPublishingContent",
    "publishingContent",
  ];

  /**
   * お試し出品モーダルが表示されるように設定する
   */
  setTrialPublishingModal() {
    this.selectPublishingTypeContentTarget.dataset.contentStatus = "hide";
    this.trialPublishingContentTarget.dataset.contentStatus = "show";
  }

  /**
   * 本出品モーダルが表示されるように設定する
   */
  setPublishingModal() {
    this.selectPublishingTypeContentTarget.dataset.contentStatus = "hide";
    this.publishingContentTarget.dataset.contentStatus = "show";
  }
}
