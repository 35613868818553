import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";

/**
 * トークの未読数を同期する
 * turboが使えるview内で処理が完結できるならこっちを使う(アプリ上にカウントを入れる必要が無い場合)
 */
export default class extends Controller {
  static values = { type: String };
  static targets = ["count"];

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "TurboTalkBadgeChannel", type: this.typeValue },
      {
        received: this.#received,
      }
    );
  }

  disconnect() {
    if (this.channel) {
      consumer.subscriptions.remove(this.channel);
    }
  }

  #received = (data) => {
    if (Object.hasOwn(data, "unread_count")) {
      for (const target of this.countTargets) {
        target.textContent = data.unread_count;
      }
    }
  };
}
