import { Controller } from "@hotwired/stimulus";

/** ホイール (スクロール) で数値の入力フィールドの値が変わってしまうのを防ぐ。
 *
 * `data-controller="disable-chage-number-on-wheel"` をつけた要素の
 * 子孫の `<input type="number">` に適用される。
 */
export default class extends Controller {
  connect() {
    const numberInputs = this.element.querySelectorAll("input[type=number]");
    numberInputs.forEach((input) => {
      input.addEventListener(
        "wheel",
        (event) => {
          event.target.blur();
        },
        { passive: true }
      );
    });
  }
}
