import { Controller } from "@hotwired/stimulus";
import { format, isToday, isYesterday } from "date-fns";
import { ja } from "date-fns/locale";

/**
 * 日時文字列を指定したフォーマットに変換する
 *
 * <%= tag.time datetime: "2024-02-15 09:48:03",
 *       data: {
 *         controller: "convert-date-string",
 *         to_format: "yyyy年MM月dd日 HH:mm", # 変換後の日時文字列
 *         to_today_format: "HH:mm", # 当日の日時文字列
 *         to_yesterday_format: "昨日 HH:mm" # 前日の日時文字列
 *       } %>
 *
 * 指定が必須なのは `controller` のみ。
 * 変更が必要な場合のみ他の値は指定する。
 * 変換後は使われたフォーマットによって、 `data-format-style` に `today`, `yesterday`, `default` のどれかが設定される。
 */
export default class extends Controller {
  connect() {
    const toFormat = this.element.dataset.toFormat || "yyyy年MM月dd日 HH:mm";
    const toTodayFormat = this.element.dataset.toTodayFormat || "HH:mm";
    const toYesterdayFormat =
      this.element.dataset.toYesterdayFormat || "昨日 HH:mm";

    const datetime = new Date(this.element.getAttribute("datetime"));

    if (isToday(datetime)) {
      this.element.textContent = format(datetime, toTodayFormat, {
        locale: ja,
      });
      this.element.dataset.formatStyle = "today";
    } else if (isYesterday(datetime)) {
      this.element.textContent = format(datetime, toYesterdayFormat, {
        locale: ja,
      });
      this.element.dataset.formatStyle = "yesterday";
    } else {
      this.element.textContent = format(datetime, toFormat, { locale: ja });
      this.element.dataset.formatStyle = "default";
    }
  }
}
