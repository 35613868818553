type Katarui = {
  katashikiSiteiBangou: string;
  ruibetsuKubunBangou: string;
};
export function parseKatarui(katarui: string): Katarui {
  const matchKatarui = katarui.match(/^(\d+)(\d{4})$/);

  let katashikiSiteiBangou = "";
  let ruibetsuKubunBangou = "";

  // 999999 は未設定値として使用されている
  if (katarui !== "999999" && matchKatarui && matchKatarui.length >= 3) {
    katashikiSiteiBangou = matchKatarui[1];
    ruibetsuKubunBangou = matchKatarui[2];
  }
  return { katashikiSiteiBangou, ruibetsuKubunBangou };
}
