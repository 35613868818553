/**
 * Turbo での画面遷移をログに残すための関数群
 */

/** Turbo Drive (Turbo での画面遷移) のログを出す。 */
export function setUpTurboDriveLog() {
  document.documentElement.addEventListener("turbo:visit", (event) => {
    const { url, action } = event.detail;

    // eslint-disable-next-line no-console
    console.log("turbo:visit", action, url);
  });
}

/** Turbo Frame のログを出す。 */
export function setUpTurboFrameLog() {
  document.documentElement.addEventListener("turbo:frame-render", (event) => {
    const { target } = event;
    const { url } = event.detail.fetchResponse.response;

    // eslint-disable-next-line no-console
    console.log("turbo:frame-render", url, target);
  });
}
