import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["confirm", "color", "odometer", "submit", "turboFrame"];

  initialize() {
    // DOM から消えるので clone しておく
    this.turboFrame = this.turboFrameTarget.cloneNode(true);
  }

  connect() {
    // 色選択状態の監視
    this.colorTarget.addEventListener("change", () => {
      this.#updateSubmitState();
    });

    // 走行距離の入力監視
    this.odometerTarget.addEventListener("input", () => {
      this.#updateSubmitState();
    });

    this.#updateSubmitState();
  }

  // モーダルを閉じて、前回表示したグラフを非表示にする
  closeModal() {
    window.requestAnimationFrame(() => {
      // 閉じるイベントを邪魔しないように待ってから置換
      this.turboFrameTarget.replaceWith(this.turboFrame.cloneNode(true));
    });
  }

  // 色と走行距離が入力された場合は送信ボタンを活性化
  #updateSubmitState() {
    this.submitTarget.disabled =
      this.colorTarget.value.length == 0 ||
      this.odometerTarget.value.length == 0;
  }
}
