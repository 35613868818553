import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

/**
 * partial を lazy-loading する
 * data-lazy-partial-url にパーシャルだけを返す url を設定しておく
 * ログイン時に「最後に見ていたページ」にならないように `skip_before_action :store_user_location!` しておく
 * 本当は turbo-frame lazy 使いたい
 * https://turbo.hotwired.dev/reference/frames
 *
 * data-controller="lazy-partial" data-lazy-partial-url="PARTIAL_URL"
 * fade-in させるには partial に .fade を振っておく
 */
export default class extends Controller {
  static targets = ["status"];
  options = {
    // 要素の縦幅が大きいので早めに発火させる(肌感)
    // top, right, bottom, left
    rootMargin: "320px 0px 320px 0px",
  };

  #addStatus(message) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent += message;
    }
  }

  connect() {
    this.#addStatus(".");
    this.unobserve = useIntersection(this, this.options)[1];
    this.url = this.data.get("url");
    if (!this.url) {
      this.#addStatus("!");
      this.unobserve();
      return;
    }
    this.#addStatus(".");
  }

  appear() {
    this.#addStatus(".");
    this.unobserve();

    this.#replace().catch((_error) => {
      this.#addStatus("!!");
    });
  }

  async #replace() {
    const res = await fetch(this.url);
    this.#addStatus(".");
    const text = await res.text();
    this.#addStatus(".");
    const html = new DOMParser().parseFromString(text, "text/html");
    this.#addStatus(".");

    // parseFromString は html > body になる
    const el = html.body.firstChild;
    this.element.parentNode.replaceChild(el, this.element);
    // 描画後に fade させたいので少し待つ（肌感）
    setTimeout(() => el.classList.add("show"), 8);
  }
}
