import { SyadaiBangou } from "./types";

export function parseSyadaiBangou(syadaiBangou: string): SyadaiBangou {
  const result = syadaiBangou.split("-");
  if (result.length >= 2) {
    return {
      frameKatashiki: result[0],
      frameBangou: result[1],
    };
  }
  return {
    frameKatashiki: "",
    frameBangou: "",
  };
}
