import { Controller } from "@hotwired/stimulus";

/** `window.open` で URL を開く。
 *
 * button:
 * ```html
 * <button type="button" data-controller="window-open" data-action="window-open#open"
 *  data-window-open-url-value="https://example.com" data-window-open-target-value="name">
 * ```
 */
export default class extends Controller {
  static values = {
    url: String,
    target: {
      type: String,
      default: "_blank",
    },
  };

  /**
   * @param {MouseEvent} event
   */
  open(event) {
    const url = this.urlValue;
    const target = this.targetValue;

    if (url) {
      window.open(url, target);
      event.preventDefault();
    }
  }
}
