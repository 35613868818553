import { Controller } from "@hotwired/stimulus";

// 選択したディーラーに応じて店舗の表示を切り替える。
// option タグは display: none を指定しても消すことができないため
// 店舗の select タグはディーラーの数だけ作成しておき、select タグ毎に
// 表示／非表示を切り替えるよう実装してある。
// <div class="container" data-controller="dealer-select">
//   ディーラー選択
//   <select data-delaer-select-target="dealer" data-action="dealer-select#update">
//     <option value="1">ウエインズトヨタ神奈川</option>
//   </select>
//   店舗選択
//   <select data-dealer-select-target="store" data-dealer-id="1">...</select>
//   <select data-dealer-select-target="store" data-dealer-id="2">...</select>
// </div>
export default class extends Controller {
  static targets = ["dealer", "store"];

  connect() {
    this.update();
  }

  update() {
    this.storeTargets.forEach((store) => {
      store.dataset.dealerId === this.dealerTarget.value
        ? $(store).prop("disabled", false).show()
        : $(store).prop("disabled", true).hide();
    });
  }
}
