import { Controller } from "@hotwired/stimulus";
import Swiper, { Lazy, Navigation, Pagination, Thumbs } from "swiper";
Swiper.use([Lazy, Navigation, Pagination, Thumbs]);

/**
 * 車両画像のカルーセル w/ サムネ
 *
 * data-controller="exhibited-car-image-swiper-with-thumbs"
 *
 * メイン
 * class="swiper-container" data-exhibited-car-image-swiper-with-thumbs-target="container"
 * サムネコンテナ
 * class="swiper-container" data-exhibited-car-image-swiper-with-thumbs-target="thumbs"
 * @see https://swiperjs.com/
 */
export default class extends Controller {
  static targets = ["container", "thumbs"];

  connect() {
    // https://swiperjs.com/demos#thumbs-gallery
    this.swiperThumbs = new Swiper(this.thumbsTarget, {
      slidesPerView: 4.25,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1,
      },
      breakpoints: {
        768: {
          slidesPerView: 5.25,
        },
      },
    });

    this.swiper = new Swiper(this.containerTarget, {
      loop: true,
      rewind: true,
      speed: 500,
      slidesPerView: 1,
      lazy: {
        loadPrevNext: false,
      },
      navigation: {
        nextEl: ".btn-swiper-next",
        prevEl: ".btn-swiper-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      thumbs: {
        swiper: this.swiperThumbs,
      },
    });
  }

  disconnect() {
    this.swiper.swiper.destroy();
    this.swiperThumbs.swiper.destroy();
  }
}
