import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
const { StreamActions } = Turbo;

const EVENT_ON_UPDATE_TALKROOM_READ_STATE =
  "talkroom:update_talkroom_read_state";
// 既読の更新を受信
// turbo-stream custom action
// https://zenn.dev/redheadchloe/articles/d567e7795f0acf
StreamActions.update_talkroom_read_state = function () {
  // `this` が turbo-stream になってるので自前で dispatch してやる
  document
    .querySelector("[data-controller=talkroom]")
    .dispatchEvent(
      new CustomEvent(EVENT_ON_UPDATE_TALKROOM_READ_STATE, { detail: this })
    );
};

class ReadState {
  #state = [];

  // 自分以外のメンバー数
  get members() {
    return this.#state.length;
  }

  constructor(readStateValue) {
    // 自分の既読状態は手前で弾いておく
    this.#state = readStateValue;
  }

  count(gid) {
    if (!this.#state) return 0;
    const a = this.#state.filter((x) => gid >= x.start && gid <= x.end);
    return a.length;
  }

  update(gid, messageId) {
    const state = this.getState(gid);
    if (!state) {
      // 初期化後に追加された人対応, 自分は手前で弾いておく
      this.#state.push({ gid: gid, start: messageId, end: messageId });
    } else {
      state.start = state.end;
      state.end = messageId;
    }
    return this;
  }

  getState(gid) {
    return this.#state.find((x) => x.gid == gid);
  }
}

export default class extends Controller {
  UNREAD_MARKER_ID = "end_of_read";

  static values = {
    url: String,
    talkroomOwnerId: String,
    lastMessageId: Number,
    readState: Array,
  };

  static targets = ["message", "infiniteLoader"];

  #readState;
  #messageControllers = {};
  #postHeaders;

  connect() {
    // 未読位置のリンクへアクセス直後スムーススクロールしないようにする
    document.documentElement.style.scrollBehavior = "auto";

    addEventListener("turbo:before-stream-render", this.#onBeforeStreamRender);
    this.element.addEventListener(
      EVENT_ON_UPDATE_TALKROOM_READ_STATE,
      this.#onUpdateTalkroomReadState
    );
    addEventListener("turbo:submit-end", this.#onSubmitEnd);

    this.#readState = new ReadState(this.readStateValue);

    // Turbo キャッシュ表示時以外, 振る舞い見ながら将来的にはプレビュー無効にするかも
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.#setupLabels();
      document
        .getElementById(this.UNREAD_MARKER_ID)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    this.#postHeaders = {
      headers: {
        "X-CSRF-TOKEN": document.querySelector("[name='csrf-token']").content,
      },
    };
  }

  disconnect() {
    // スムーススクロールの設定を戻しておく
    document.documentElement.style.scrollBehavior = "";

    removeEventListener(
      "turbo:before-stream-render",
      this.#onBeforeStreamRender
    );
    this.element.removeEventListener(
      EVENT_ON_UPDATE_TALKROOM_READ_STATE,
      this.#onUpdateTalkroomReadState
    );
    removeEventListener("turbo:submit-end", this.#onSubmitEnd);
  }

  #setupLabels() {
    if (!this.messageTargets) return;
    this.#setDateLabels();
    this.#addUnreadLabel();
  }

  // 日付ラベル
  #addDateLabel(el) {
    const id = `date-${el.dataset.dateId}`;
    if (document.getElementById(id)) return;

    const div = document.createElement("div");
    div.classList.add("message-date-separator");
    el.parentNode.insertBefore(div, el);

    var badge = div.appendChild(document.createElement("time"));
    badge.setAttribute("datetime", el.dataset.dateText);
    badge.id = id;
    badge.classList.add("message-date");
    badge.dataset.controller = "convert-date-string";
    badge.dataset.toFormat = "MM/dd(E)";
    badge.dataset.toTodayFormat = "今日";
    badge.dataset.toYesterdayFormat = "昨日";
  }

  // ここから未読マーカー, トークを開いたときに設定する（開きっぱなしだったらそのまま（でいいのかは今後検証））
  #addUnreadLabel() {
    if (this.messageTargets.length == 0) return;
    // 設定ずみだったら無視
    if (document.getElementById(this.UNREAD_MARKER_ID)) return;

    const messages = this.messageTargets.reverse();
    // 最新メッセージ
    const last = messages[0];
    // 最終既読位置
    let latest = null;

    if (this.hasLastMessageIdValue) {
      // 初めて開いた時は表示なし
      if (this.lastMessageIdValue == 0) return;

      // 未読メッセージ
      const unreadMessages = this.messageTargets.filter(
        (message) =>
          message.dataset.talkroomMessageMessageIdValue >
            this.lastMessageIdValue &&
          message.dataset.senderDomId != this.talkroomOwnerIdValue
      );

      // 未読メッセージがある場合は未読の最初のメッセージをセットする
      if (unreadMessages.length > 0) {
        latest = unreadMessages[0];
      }
    }

    const div = document.createElement("div");
    div.id = this.UNREAD_MARKER_ID;

    if (latest) {
      div.classList.add("my-3");
      const childDiv = document.createElement("div");
      childDiv.textContent = "ここから未読メッセージ";
      childDiv.classList.add("end-of-read", "text-center");
      div.appendChild(childDiv);
      this.element.insertBefore(div, latest);
    } else {
      this.element.insertBefore(div, last.nextSibling);
    }
  }

  #onBeforeStreamRender = (event) => {
    const render = event.detail.render;

    const isScrollBottom = this.#detectScrollBottom();
    const currentScrollY = window.scrollY;

    event.detail.render = (streamElement) => {
      if (streamElement.action == "append") {
        // ポスト時に追加済みの場合は無視
        const frame = streamElement
          .querySelector("template")
          .content.querySelector("turbo-frame");
        if (document.getElementById(frame.id)) return;
      }

      const currRect = this.element.getBoundingClientRect();
      render(streamElement);

      // after render
      const newRect = this.element.getBoundingClientRect();

      // lazy loading が連続で呼ばれないように範囲外になるようスクロール
      if (newRect.top > 0 && currRect.height < newRect.height) {
        const margin = this.hasInfiniteLoaderTarget
          ? this.infiniteLoaderTarget.getBoundingClientRect().height
          : 0;

        const pos = currentScrollY + newRect.height - currRect.height - margin;
        window.scroll({ top: pos, behavior: "instant" });
      }

      this.#setDateLabels();

      // スクロール位置が一番下になっていた場合は下にスクロールする
      if (isScrollBottom) {
        const lastMessage = this.messageTargets.reverse()[0];
        lastMessage.scrollIntoView({ behavior: "smooth" });
      }
    };
  };

  #onSubmitEnd = () => {
    // #onSubmitEnd = ({ detail }) => {
    // TODO: 送信成功時にフォームクリア
  };

  // 既読更新のコールバック
  #onUpdateTalkroomReadState = (ev) => {
    const el = ev.detail;
    const gid = el.getAttribute("member_gid");
    if (this.talkroomOwnerIdValue == gid) return;

    const messageId = el.getAttribute("message_id");
    const state = this.#readState.update(gid, messageId).getState(gid);
    this.#updateReadCounts(state);
  };

  #updateReadCounts(state) {
    const members = this.#readState.members;
    for (const key in this.#messageControllers) {
      // 連打でたまに取りこぼすので最新の既読位置からスタート, 最新まで既読にするので end は無視
      if (this.#messageControllers[key].messageIdValue < state.start) continue;
      const count = this.#readState.count(key);
      this.#messageControllers[key].setReadCount(count, members);
    }
  }

  #lastTimeoutId = null;

  connected({ detail: { content } }) {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;
    this.#messageControllers[content.messageIdValue] = content;

    content.setReadCount(
      this.#readState.count(content.messageIdValue),
      this.#readState.members
    );

    if (this.talkroomOwnerIdValue == content.element.dataset.senderDomId) {
      // 自分のものだったらマークする
      content.setOwned();
      return;
    }

    // 既読管理をしない場合は終了
    if (!this.hasLastMessageIdValue) return;

    // 既読位置の更新はある程度まとめて送る
    if (this.#lastTimeoutId) {
      clearTimeout(this.#lastTimeoutId);
      this.#lastTimeoutId = null;
    }
    this.#lastTimeoutId = setTimeout(() => {
      fetch(this.#getMessagePath(content.messageIdValue, "read"), {
        method: "PATCH",
        ...this.#postHeaders,
      });
    }, 150);
  }

  /**
   * 日付ラベルをセットし直す
   */
  resetDateLabels() {
    if (!this.messageTargets) return;

    // 一番上にあるメッセージの日付ラベルを構築し直す
    const topMessage = this.messageTargets[0];
    const id = `date-${topMessage.dataset.dateId}`;
    if (document.getElementById(id)) {
      document.getElementById(id).remove();
    }

    this.#setDateLabels();
  }

  /**
   * 日付ラベルをセットする
   */
  #setDateLabels() {
    const dates = {};
    this.messageTargets.forEach((x) => {
      // message.id は歯抜けになるし舐めるのが良さそう
      if (!dates[x.dataset.dateId]) {
        dates[x.dataset.dateId] = x;
      }
    });
    for (const key in dates) {
      this.#addDateLabel(dates[key]);
    }
  }

  /**
   * @param {Number} id message.id
   * @param {String} action action name (optional)
   * @returns {String} path
   */
  #getMessagePath(id, action = null) {
    const path = `${this.urlValue}/${id}`;
    if (!action) {
      return path;
    }
    return `${path}/${action}`;
  }

  /**
   * スクロールが一番下に達しているか検知する
   */
  #detectScrollBottom() {
    const documentHeight = document.documentElement.scrollHeight;
    const currentScroll = window.innerHeight + window.scrollY;

    return currentScroll >= documentHeight;
  }
}
