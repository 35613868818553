import { Controller } from "@hotwired/stimulus";
// https://github.com/stimulus-use/stimulus-use/blob/main/docs/use-intersection.md
import { useIntersection } from "stimulus-use";

/**
 * viewport に入ったら data-img-lazy-src で上書きする
 * img-src には低解像度とかスケルトンなど, 空はあまり良くない(必須属性)
 * image_path "blank.gif" が使える
 *
 * <%= image_tag "", class: "",
 *   decoding: :async, data: { controller: "img-lazy", img_lazy_src: image_path() },
 *   alt: "" %>
 */
export default class extends Controller {
  options = {
    // top, right, bottom, left
    rootMargin: "96px 0px 96px 0px",
  };

  connect() {
    if (!this.data.has("src")) {
      console.warn("missing attribute data-img-lazy-src");
      return;
    }
    this.unobserve = useIntersection(this, this.options)[1];
  }

  appear() {
    this.element.src = this.data.get("src");
    this.unobserve();
  }
}
