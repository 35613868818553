import { Controller } from "@hotwired/stimulus";
import { readFileAsDataURL } from "../../shared/readFileAsDataURL";

function removeAllChilds(node) {
  while (node.hasChildNodes()) {
    node.removeChild(node.lastChild);
  }
}

function createElementAndSetUp(fileData, container, onLoaded) {
  const imageTag = document.createElement("img");
  imageTag.src = fileData;
  imageTag.className = "img-fluid";

  container.appendChild(imageTag);
  // 要素の描画が終わってないとサイズ取れない
  imageTag.onload = () => {
    if (!onLoaded) return;
    onLoaded(imageTag);
  };
}

function cloneElementAndSetUp(fileData, container, template, onLoaded) {
  const content = template.content.cloneNode(true);
  const images = content.querySelectorAll("img");
  images.forEach((imageTag) => {
    imageTag.src = fileData;
    // 要素の描画が終わってないとサイズ取れない
    imageTag.onload = () => {
      if (!onLoaded) return;
      onLoaded(imageTag);
    };
  });

  container.appendChild(content);
}

function isPortrait(img) {
  if (!img.naturalWidth) return false;
  return img.naturalHeight > img.naturalWidth;
}

/** form.file_field で選択した画像ファイルをプレビューする。
 *
 * 使い方:
 * 1. form.file_field に `data: {image_preview_target: "form", action: "image-preview#preview"}` を追加。
 * 2. プレビューとして画像を追加する要素に `data-image-preview-target="container"` を追加。
 *    image-preview.container は画像の preview 実行時に子要素が全て取り除かれるので注意
 * 3. (必要なら) container の要素の中身になるテンプレート `<template data-image-preview-target="template">` を追加。
 * 4. (必要なら) プレビュー時に削除する要素(複数可)に `data-image-preview-target="removeField"` を追加。
 *    エラー時にファイルの選択状態が保持されるように使う hidden_field 等に使用できる。
 */
export default class extends Controller {
  static targets = ["form", "container", "template", "removeField"];

  connect() {
    // safari はアップロード中に画面遷移しようとすると xhr.error 扱いなのでフラグ立てて判定する
    let willUnload = false;
    window.addEventListener("beforeunload", () => {
      willUnload = true;
    });
    document.addEventListener("direct-upload:error", (ev) => {
      ev.preventDefault();
      if (willUnload) return;
      // 画面遷移以外はメッセージ表示してリロード
      // 元のイベントメッセージはベタ書きなので捨てる
      alert("通信エラーが発生しました");
      location.reload();
    });
  }

  preview() {
    if (!this.hasFormTarget || !this.hasContainerTarget) {
      return;
    }

    const form = this.formTarget;
    if (form.files.length < 1) {
      return;
    }

    const file = form.files[0];
    this.#previewImage(file, (img) => {
      if (isPortrait(img)) {
        this.element.classList.add("portrait");
      } else {
        this.element.classList.remove("portrait");
      }
    });

    this.removeFieldTargets.forEach((x) => x.remove());
  }

  async #previewImage(file, onLoaded) {
    const fileData = await readFileAsDataURL(file);

    const container = this.containerTarget;
    removeAllChilds(container);

    if (this.hasTemplateTarget) {
      cloneElementAndSetUp(fileData, container, this.templateTarget, onLoaded);
    } else {
      createElementAndSetUp(fileData, container, onLoaded);
    }
  }
}
