import { Controller } from "@hotwired/stimulus";
import { delay } from "../../shared/delay";

/**
 * bootstrap tooltip をロード時に表示させる
 *
 * data-controller="activate-tooltip"
 */
export default class extends Controller {
  connect() {
    this.#setUp();
  }

  async #setUp() {
    await delay(166);
    $(this.element).tooltip("show");
  }
}
