import { Controller } from "@hotwired/stimulus";
import Scroller from "../../shared/smooth_scroll";

// 刺された要素以下のページ内リンクを smooth-scroll
// data-controller="smooth-scroll"
// 時間指定 [=400]
// data-smooth-scroll-duration="{msec}"
// スクロールする要素を指定(html要素以外)の場合
// data-smooth-scroll-selector="{selector}"
export default class extends Controller {
  initialize() {
    this.click = this.onClick.bind(this);
  }
  connect() {
    if (this.data.get("selector")) {
      this.scrollElement = document.querySelector(this.data.get("selector"));
    } else {
      this.scrollElement = document;
    }
    this.scroller = new Scroller(this.scrollElement);
    const d = parseInt(this.data.get("duration"));
    this.duration = isNaN(d) ? 400 : d;
    this.links = this.element.querySelectorAll('a[href^="#"]');
    this.observe();
  }
  disconnect() {
    this.scroller = null;
    this.unobserve();
  }
  observe() {
    this.links.forEach((el) => {
      el.addEventListener("click", this.click);
    });
  }
  unobserve() {
    this.links.forEach((el) => {
      el.removeEventListener("click", this.click);
    });
  }

  onClick(ev) {
    const anchor = ev.currentTarget.getAttribute("href");
    const target = document.querySelector(anchor);
    if (!target) return;

    ev.preventDefault();

    this.scroller.scroll(target, this.duration);
  }
}
