// 画面スクロールを抑止する
export default function () {
  // スクロールを止めたいので passive: false にする。
  // eslint-disable-next-line github/require-passive-events
  document.addEventListener(
    "touchmove",
    (e) => {
      e.preventDefault();
    },
    { passive: false }
  );
  document.body.style = "overflow:hidden";
}
