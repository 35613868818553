import { Controller } from "@hotwired/stimulus";

/**
 * 車両の概算見積及び購入申請
 *   price: 計算する対象の金額(typeがcheckboxの場合はチェック時に計算に含まれる)
 *   amount: リクエスト送信する合計金額
 *   amountPlaceholder: 表示する合計金額
 *   aioiWarrantyYear: 修理保証のcheckbox
 *   warrantyAgreements: 修理保証ガイドのセクション
 *   confirmPurchase: 購入申請のsubmit
 *   warrantyAgreed: 修理保証の同意checkbox
 */
export default class extends Controller {
  static targets = [
    "price",
    "amount",
    "amountPlaceholder",
    "aioiWarrantyYear",
    "warrantyAgreements",
    "confirmPurchase",
    "warrantyAgreed",
  ];

  connect() {
    this.preventSubmitLocked = false;
    this.setActiveWarrantyAgreement();
    this.sumAmount();
  }

  /**
   * 金額合計を計算する
   */
  sumAmount() {
    const prices = this.priceTargets.map((price) => {
      if (price.type === "checkbox") {
        return price.checked ? Number(price.dataset.price) : 0;
      } else {
        return Number(price.dataset.price);
      }
    });
    const sum = prices.reduce((sum, price) => sum + price);

    this.amountPlaceholderTargets.forEach((amountPlaceholder) => {
      if (isNaN(sum)) {
        amountPlaceholder.textContent = "---";
      } else {
        amountPlaceholder.textContent = sum.toLocaleString();
      }
    });

    this.amountTarget.value = sum;
  }

  /**
   * 修理保証のオプション設定時の保証ガイド表示
   */
  setActiveWarrantyAgreement() {
    if (this.aioiWarrantyYearTarget.checked) {
      $(this.warrantyAgreementsTarget).removeClass("d-none");
      this.confirmPurchaseTarget.disabled = true;
      this.warrantyAgreedTarget.checked = false;
    } else {
      $(this.warrantyAgreementsTarget).addClass("d-none");
      this.confirmPurchaseTarget.disabled = false;
    }
  }

  /**
   * ボタンクリック時に一時的に無効化する
   * disable-with だとPDF開いた時にリダイレクト等で制御できないので自前で無効にして戻すようにした
   * @param {PointerEvent} event
   */
  preventSubmitOnClick(event) {
    if (this.preventSubmitLocked) {
      return event.preventDefault();
    }

    this.preventSubmitLocked = true;
    const target = $(event.currentTarget);
    const originalHtml = target.html();
    target.text("送信中...");

    setTimeout(() => {
      this.preventSubmitLocked = false;
      target.html(originalHtml);
    }, 2000);
  }
}
