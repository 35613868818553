import { Controller } from "@hotwired/stimulus";

/** TextAreaまたはTextFieldに文字が1文字以上入力された場合にボタンを活性化する。
 *
 * 使用例:
 * ```
 * <form data-controller="input-text-change">
 *   <textarea data-input-text-change-target="text">...</textarea>
 *   <button type="button" data-input-text-change-target="button">...</button>
 * </form>
 * ```
 */
export default class extends Controller {
  static targets = ["text", "button"];

  connect() {
    this.textTarget.addEventListener("input", this.#enableButton);
  }

  disconnect() {
    this.textTarget.removeEventListener("input", this.#enableButton);
  }

  #enableButton = () => {
    this.buttonTarget.disabled = this.textTarget.value.length == 0;
  };
}
