import { useState, useCallback } from "react";

type AsyncRunner = (func: () => Promise<void>) => void;

/**
 * @returns async 関数を受け取って、それを実行する関数。実行時のエラーは ErrorBoundary に飛ばす。
 */
export const useAsyncCatchError: () => AsyncRunner = () => {
  const [, setError] = useState();
  const run = useCallback<AsyncRunner>((func: () => Promise<void>) => {
    (async () => {
      try {
        await func();
      } catch (error) {
        console.error(error);

        // エラーを ErrorBoundary に飛ばす。
        // https://qiita.com/sosukesuzuki/items/5ef84f4776dc4c457bb6
        // https://github.com/facebook/react/issues/14981#issuecomment-468460187
        setError(() => {
          throw error;
        });
      }
    })();
  }, []);

  return run;
};
