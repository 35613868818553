import React, {
  createContext,
  useContext,
  useState,
  ReactEventHandler,
} from "react";
import ImageMessageModal from "components/ImageMessageModal";

interface State {
  url: string | null;
  setUrl: (imageUrl: string | null) => void;
}

const Context = createContext<State>({
  url: null,
  setUrl: (imageUrl: string | null) => {
    return imageUrl;
  },
});

export const ImageMessageProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [url, setUrl] = useState<string | null>(null);
  const [enableZoom, setEnableZoom] = useState<boolean>(false);

  const handleImageLoaded: ReactEventHandler<HTMLImageElement> = (event) => {
    const img = event.target as HTMLImageElement;

    if (img && img.naturalWidth > 0 && img.naturalHeight > 0) {
      setEnableZoom(true);
    }
  };

  return (
    <Context.Provider value={{ url, setUrl }}>
      {children}
      {url !== null ? (
        <>
          <ImageMessageModal
            onClose={() => setUrl(null)}
            enableZoom={enableZoom}
          >
            <img
              className="img-fluid"
              src={url}
              onLoad={handleImageLoaded}
              decoding="async"
            />
          </ImageMessageModal>
          <div className="modal-backdrop fade show" />
        </>
      ) : null}
    </Context.Provider>
  );
};

export const useImageMessageContext: () => State = () => useContext(Context);
