import { Controller } from "@hotwired/stimulus";

/** iframe-event */
export default class extends Controller {
  connect() {
    if (window.parent === window) return;

    window.parent.postMessage({
      type: "iframeConnected",
    });
  }
}
