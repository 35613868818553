import { CarInfo, CarSpec } from "./types";
import { parseKatarui } from "./parseKatarui";
import { parseNumberPlate } from "./parseNumberPlate";
import { parseSyadaiBangou } from "./parseSyadaiBangou";

function optionalValue(value: string): string | null {
  if (
    value === "-" ||
    value === "*" ||
    value === "***" ||
    value === "ー" ||
    value === "＊" ||
    value.match(/^[-*]+\x20*$/)
  ) {
    return null;
  }

  return value;
}

export function parseHutsuuQR2(qr: string): CarInfo | null {
  // eslint-disable-next-line no-irregular-whitespace
  // 2/横浜　　３０５す４５４４/1/ZVW55-6012441/2ZR-1NM-1MM/1
  const regex =
    /^[^\\/]*?\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }
  const syaryouBangou = parseNumberPlate(match[1]);
  const numberPlateInfo = optionalValue(match[2]);
  const syadaiBangou = parseSyadaiBangou(match[3]);
  const gendoukiKatashiki = optionalValue(match[4]);
  const chouhyouType = match[5];
  return {
    syaryouBangou,
    numberPlateInfo,
    syadaiBangou,
    gendoukiKatashiki,
    chouhyouType,
  };
}

export function parseHutsuuQR3(qr: string): CarSpec | null {
  // 2/-  /182090023/221223/1912/DAA-ZVW55/0084/-   /-   /0061/10/096/1/0/I/0001/-    /191210/01
  const regex =
    /^[^\\/]*?\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }

  const syadaiBangouDakokuIchi = optionalValue(match[1]);
  const { katashikiSiteiBangou, ruibetsuKubunBangou } = parseKatarui(match[2]);
  const yuukouKikanManryoubi = !match[3]
    ? { year: null, month: null, day: null }
    : {
        year: "20" + match[3].substr(0, 2),
        month: match[3].substr(2, 2),
        day: match[3].substr(4, 2),
      };
  const shodoKensaNengetsu = {
    year: match[4].substr(0, 2) === "99" ? null : "20" + match[4].substr(0, 2),
    month: match[4].substr(2, 2) === "99" ? null : match[4].substr(2, 2),
  };
  const katashiki = match[5];
  const jikujuFF = optionalValue(match[6]);
  const jikujuFB = optionalValue(match[7]);
  const jikujuBF = optionalValue(match[8]);
  const jikujuBB = optionalValue(match[9]);
  const souonKisei = optionalValue(match[10]);
  const kinsetsuHaikiSouonKiseiNum = optionalValue(match[11]);
  const kudouHoushiki = optionalValue(match[12]);
  const opaciMeterSokuteiSya = optionalValue(match[13]);
  const noxPmSokuteiMode = optionalValue(match[14]);
  const noxNum = optionalValue(match[15]);
  const pmNum = optionalValue(match[16]);
  const hoanKijunTekiyouYMD = optionalValue(match[17]);
  const nenryouTypeCode = optionalValue(match[18]);

  return {
    syadaiBangouDakokuIchi,
    katashikiSiteiBangou,
    ruibetsuKubunBangou,
    yuukouKikanManryoubi,
    shodoKensaNengetsu,
    katashiki,
    jikujuFF,
    jikujuFB,
    jikujuBF,
    jikujuBB,
    souonKisei,
    kinsetsuHaikiSouonKiseiNum,
    kudouHoushiki,
    opaciMeterSokuteiSya,
    noxPmSokuteiMode,
    noxNum,
    pmNum,
    hoanKijunTekiyouYMD,
    nenryouTypeCode,
  };
}
