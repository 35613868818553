import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="store-reservation-calendar"
 * data-store-reservation-calendar-timespans="<%= @reservable_store_schedule_time_index_label %>"
 *
 * フォームとやりとりするために dispatch 使ってる
 * https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
 */
export default class extends Controller {
  static targets = ["day", "currentDate", "checkbox", "submit"];

  connect() {
    this.currentDate = null;
    this.reservations = [];
    this.submitTarget.disabled = true;
    this.timespans = JSON.parse(this.data.get("timespans"));
    // カレンダー受信を通知, 構成上フォームからは observe できないので controller ごと渡す
    // listener: data-action="store-reservation-calendar:connected->controller#method"
    this.dispatch("connected", { detail: { controller: this } });
  }

  // 日付選択時
  onDaySelected(ev) {
    if (this.currentDate == ev.currentTarget.dataset.date) {
      this.currentDate = null;
      this.#showTimeline(false);
      return;
    }

    this.currentDate = ev.currentTarget.dataset.date;
    this.#showTimeline(true);
    this.#changeDay(ev.currentTarget);
  }

  #changeDay(el) {
    const ts = JSON.parse(el.dataset.timespan);
    this.checkboxTargets.forEach((el, idx) => {
      // 店休、ストール設定なしなどは空の配列 → 予約申込不可
      el.disabled = ts.length == 0 || ts[idx] == 0;
    });

    this.#updateFormStatus();
    this.currentDateTarget.innerText = this.#getLocaleDateString(
      this.currentDate
    );
  }

  #jumpDay(days) {
    const idx = this.dayTargets.findIndex(
      (x) => x.dataset.date == this.currentDate
    );
    const day = this.dayTargets[idx + days];

    if (!day) return;

    this.currentDate = day.dataset.date;
    this.#changeDay(day);
  }

  nextDay() {
    this.#jumpDay(1);
  }

  prevDay() {
    this.#jumpDay(-1);
  }

  closeTimeline() {
    this.#showTimeline(false);
  }

  // 時間帯選択時
  onTimespanSelected(ev) {
    if (!this.currentDate) return;

    const key = `${this.currentDate}:${ev.currentTarget.value}`;
    if (ev.currentTarget.checked) {
      if (this.reservations.length == 3) {
        ev.currentTarget.checked = false;
        ev.preventDefault();
        return false;
      }
      this.reservations.push(key);
    } else {
      this.reservations = this.reservations.filter((x) => x != key);
    }
    this.#updateFormStatus();
  }

  /**
   * @param {string} date 日付
   */
  #dayTargetExists(date) {
    return this.dayTargets.find((el) => el.dataset.date == date) ? true : false;
  }

  // フォームの状態制御
  #updateFormStatus() {
    let satisfied = this.reservations.length == 3;

    this.checkboxTargets.forEach((x) => {
      // 一旦全部 false
      x.checked = false;
      x.classList.remove("checked-0", "checked-1", "checked-2");
      if (satisfied) {
        x.classList.add("disabled");
      } else {
        x.classList.remove("disabled");
      }
    });

    const invalidatedKeys = [];

    // 選択済みの時間帯にチェック
    this.reservations.forEach((x, idx) => {
      // [date, index]
      const checked = x.split(":");
      if (!this.#dayTargetExists(checked[0])) {
        // 予約可能日以外
        invalidatedKeys.push(x);
      }
      // 時間外は普通こないはず
      if (this.checkboxTargets[checked[1]]) {
        if (checked[0] == this.currentDate) {
          if (this.checkboxTargets[checked[1]].disabled) {
            // 編集時点で埋まってる時間帯
            invalidatedKeys.push(x);
          } else {
            this.checkboxTargets[checked[1]].checked = true;
            this.checkboxTargets[checked[1]].classList.remove("disabled");
            this.checkboxTargets[checked[1]].classList.add(`checked-${idx}`);
          }
        }
      }
    });

    // チェック入れられなかった時間を消す, その日を表示しないと判定できない…
    // 設定されてる日の dayTargets.dataset.timespan までチェックすればできる
    // チェック外れた場合は何かしらUIにフィードバックあった方がいいかもしれない
    if (invalidatedKeys.length > 0) {
      this.reservations = this.reservations.filter(
        (y) => !invalidatedKeys.includes(y)
      );
      satisfied = false;
    }

    const selectedDays = Array.from(
      new Set(this.reservations.map((x) => x.split(":")[0]))
    );

    // 選択中のマーク
    this.dayTargets.forEach((day) => {
      if (selectedDays.includes(day.dataset.date)) {
        day.classList.add("checked");
      } else {
        day.classList.remove("checked");
      }
    });

    this.submitTarget.disabled = !satisfied;
  }

  submit() {
    this.#showTimeline(false);
    const values = this.reservations.map((x) => {
      const v = x.split(":");
      const time = this.timespans[v[1]];
      return `${v[0]}T${time[0]}`;
    });
    // 選択内容をフォーム側に通知
    // listener: data-action="store-reservation-calendar:dateSelected->controller#method"
    this.dispatch("dateSelected", { detail: { values: values } });
  }

  clear() {
    this.reservations = [];
    this.#updateFormStatus();
  }

  #showTimeline(state) {
    if (state) {
      this.element.classList.add("slide");
    } else {
      this.element.classList.remove("slide");
      this.currentDate = null;
    }
  }

  #getLocaleDateString(dateString) {
    const date = new Date(dateString);
    if (!date) return "";
    // Date は UTC で入ってるので曜日も UTC で取得する
    // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    return date.toLocaleString("ja-JP", {
      timeZone: "UTC",
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "short",
    });
  }

  /**
   * フォームの初期値をカレンダーに設定
   * @param {Array<String>} dates 日付リスト
   */
  setDates(dates) {
    dates.forEach((x) => {
      const date = new Date(x);
      const time = `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      const index = this.timespans.findIndex((x) => x[0] == time);
      const key = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}:${index}`;
      this.reservations.push(key);
    });
    this.#updateFormStatus();
  }
}
