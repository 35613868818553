import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";
import { getTwilioConnection } from "../../shared/TwilioConnection";

/**
 * アプリのフッターメニューのトークバッジの未読数を同期する。
 *
 * data-controller="talk-badge"
 */
export default class extends Controller {
  static values = { accessToken: String, conversationSids: Array };

  connect() {
    // Twilio に直接接続して取得した未読数。
    this.twilioApiCount = 0;

    // TalkBadgeChannel 経由で取得した未読数。
    this.twilioChannelCount = 0;
    this.broadcastCount = 0;
    this.talkCount = 0;

    this.channel = consumer.subscriptions.create("TalkBadgeChannel", {
      received: this.#received,
    });

    // MobilicoApp の準備ができてなかった場合でも、後から送る。
    document.addEventListener("MobilicoAppLoaded", this.#trySendCountToApp);

    // Twilio の未読件数を監視する。
    this.#setUpTwilio();
  }

  disconnect() {
    if (this.channel) {
      consumer.subscriptions.remove(this.channel);
    }
    document.removeEventListener("MobilicoAppLoaded", this.#trySendCountToApp);
    getTwilioConnection().unwatchUnreadMessagesCount(this.#updateTwilioCount);
  }

  #received = (data) => {
    if (Object.hasOwn(data, "twilio")) {
      this.twilioChannelCount = data.twilio;
    }
    if (Object.hasOwn(data, "broadcast")) {
      this.broadcastCount = data.broadcast;
    }
    if (Object.hasOwn(data, "talk")) {
      this.talkCount = data.talk;
    }

    // 未読件数を送る。
    this.#trySendCountToApp();
  };

  #count() {
    // システムメッセージ等で正しい未読数を記録できていなかったので、
    // this.twilioApiCount と this.twilioChannelCount の大きい方を使う。
    const twilioCount = Math.max(this.twilioApiCount, this.twilioChannelCount);

    return twilioCount + this.broadcastCount + this.talkCount;
  }

  #trySendCountToApp = () => {
    // 確認しやすいように画面にも出しておく。
    this.element.textContent = `max(${this.twilioApiCount}, ${
      this.twilioChannelCount
    }) + ${this.broadcastCount} + ${this.talkCount} = ${this.#count()}`;

    // アプリに未読件数を渡す。
    window.MobilicoApp?.updateUnreadMessagesCount?.(this.#count());
  };

  #setUpTwilio() {
    const accessToken = this.accessTokenValue;
    const conversationSids = this.conversationSidsValue;
    if (!accessToken || !conversationSids) {
      return;
    }

    getTwilioConnection().setAccessToken(accessToken);
    getTwilioConnection().watchUnreadMessagesCount(
      this.#updateTwilioCount,
      conversationSids
    );
  }

  #updateTwilioCount = (count) => {
    this.twilioApiCount = count;

    this.#trySendCountToApp();
  };
}
