import { Controller } from "@hotwired/stimulus";

// パスワードフォームのマスクを解除するやつ. IE では動かないらしい(setAttributeあたり)
//
// パスワードフォームの親に刺す
// password_field に data: { password_masking_target: 'input' }
// イベント発火要素で data-password-masking-target="button"
export default class extends Controller {
  static targets = ["input", "button"];

  connect() {
    this.buttonTarget.addEventListener("click", () => {
      this.toggle();
    });
    this.inputTarget.form.addEventListener("submit", () => {
      this.onSubmit();
    });
  }

  toggle() {
    if (this.inputTarget.getAttribute("type") == "password") {
      this.inputTarget.setAttribute("type", "text");
      this.buttonTarget.classList.add("visualized");
    } else {
      this.inputTarget.setAttribute("type", "password");
      this.buttonTarget.classList.remove("visualized");
    }
  }

  onSubmit() {
    this.inputTarget.setAttribute("type", "password");
  }
}
