import { Controller } from "@hotwired/stimulus";
import { WebShareNavigator } from "shared/WebShareNavigator";

/**
 * title + URL の web-share-api 共有
 * data-controller="web-share" data-action="click->web-share#click"
 */
export default class extends Controller {
  connect() {
    this.shareData = {
      title: document.title,
      text: document.title,
      url: location.href,
    };

    if (!WebShareNavigator.canShare(this.shareData)) {
      this.element.remove();
    }
  }

  click() {
    try {
      WebShareNavigator.share(this.shareData);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
