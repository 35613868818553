/** `<input>` の直前に同じ name の hidden input を作る。
 * @param {HTMLInputElement} input
 * @returns {HTMLInputElement} hiddenInput
 */
export function createSameNameHiddenInput(input, value) {
  // activestorage と同じように beforebegin に置いてみる。
  // https://github.com/rails/rails/blob/b2eb1d1c55a59fee1e6c4cba7030d8ceb524267c/activestorage/app/javascript/activestorage/direct_upload_controller.js
  const hiddenInput = document.createElement("input");
  hiddenInput.type = "hidden";
  hiddenInput.name = input.name;
  hiddenInput.value = value;
  input.insertAdjacentElement("beforebegin", hiddenInput);
  return hiddenInput;
}
