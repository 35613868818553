import { Controller } from "@hotwired/stimulus";

/**
 * モーダル内で内容を切り替える
 *
 * 使用例
 * ```
 * <%= tag.section id: "modal", class: "modal fade",
 *    tabindex: "-1", role: "dialog",
 *    data: { controller: "change-modal-content" } do %>
 *
 *   <%# 順番通り表示するだけの内容 %>
 *   <section class="modal-content-wrapper" data-change-modal-content-target="modalContent">
 *   </section>
 *
 *   <%# 名前指定で呼び出せる内容 %>
 *   <%= tag.section class: "modal-content-wrapper",
 *         data: {
 *          change_modal_content_target: "modalContent",
 *          content_name: "contentName"
 *        } do %>
 *   <% end %>
 *
 *   <%# 次の内容を表示する %>
 *   <%= button_tag "次へ", type: "button",
 *         data: { action: "click->change-modal-content#nextModalContent" } %>
 *
 *   <%# チェックされた場合に次の内容を表示する %>
 *   <%# モーダルを閉じた時にチェックを外したいので、resetCheckboxもつける %>
 *   <%= check_box_tag "agree-publish-regulation", nil, false, {
 *           data: {
 *             action: "click->change-modal-content#onCheckNextModalContent",
 *             change_modal_content_target: "resetCheckbox"
 *           }
 *         } %>
 *
 *   <%# 前の内容を表示する %>
 *   <%= button_tag "戻る", type: :button,
 *         data: { action: "click->change-modal-content#prevModalContent" } %>
 *
 *   <%# 指定の位置を表示する %>
 *   <%= button_tag "指定のコンテンツへ", type: :button,
 *         data: {
 *           action: "click->change-modal-content#showModalContentByName",
 *           to_content_name: "contentName"
 *         } %>
 * <% end %>
 * ```
 */

export default class extends Controller {
  static targets = ["modalContent", "resetCheckbox"];

  connect() {
    this.currentIndex = 0;
    this.#showModalContent();

    // モーダルの閉じた時の状態を購読する
    $(this.element).on("hidden.bs.modal", this.#modalReset.bind(this));
  }

  /**
   * 特定名に該当するコンテンツを表示する
   * @param {PointerEvent} event
   */
  showModalContentByName(event) {
    const contentName = event.target.dataset.toContentName;

    for (const [index, content] of this.modalContentTargets.entries()) {
      if (content.dataset.contentName === contentName) {
        content.dataset.contentStatus = "show";
        this.currentIndex = index;
      } else {
        content.dataset.contentStatus = "hide";
      }
    }
  }

  /**
   * チェックされた場合に次のコンテンツを表示する
   * @param {PointerEvent} event
   */
  onCheckNextModalContent(event) {
    if (event.target.checked) {
      this.nextModalContent();
    }
  }

  /**
   * 次のコンテンツを表示する
   */
  nextModalContent() {
    this.currentIndex++;
    this.#showModalContent();
  }

  /**
   * 前のコンテンツを表示する
   */
  prevModalContent() {
    this.currentIndex--;
    this.#showModalContent();
  }

  /**
   * リセット対象のチェックボックスを外す
   */
  resetCheckbox() {
    for (const checkbox of this.resetCheckboxTargets) {
      checkbox.checked = false;
    }
  }

  /**
   * 現在位置のコンテンツを表示する
   */
  #showModalContent() {
    for (const [index, content] of this.modalContentTargets.entries()) {
      content.dataset.contentStatus =
        index === this.currentIndex ? "show" : "hide";
    }
  }

  /**
   * モーダルコンテンツの初期化
   */
  #modalReset() {
    // モーダルを閉じた場合は位置をリセットする
    if (this.element.style.display === "none") {
      this.currentIndex = 0;
      this.#showModalContent();

      this.resetCheckbox();
    }
  }
}
