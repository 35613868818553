import { Controller } from "@hotwired/stimulus";

/**
 * オンボーディングの閲覧状況を非同期で更新する
 *
 * 使用例:
 * <%= tag.div data: { controller: "onboarding" } do %>
 *   <%= button_tag "更新する", type: :button, data: {
 *         action: "onboarding#updateViewedStatus",
 *         onboarding_next_view_param: "#edit" #更新後の遷移先がある場合、指定
 *       } %>
 *
 *   <%= form_with scope: :onboarding, url: onboarding_path, method: :patch,
 *         data: { onboarding_target: "form" } do |f| %>
 *     <%# viewed_status のパラメーターで更新したい値を指定する %>
 *     <%= f.hidden_field :viewed_status, value: "show_mypage_listing" %>
 *   <% end %>
 * <% end %>
 */
export default class extends Controller {
  static targets = ["form"];

  /**
   * オンボーディング閲覧状況を更新する
   * @param {PointerEvent} event
   */
  async updateViewedStatus(event) {
    const nextView = event.params.nextView;
    const formData = new FormData(this.formTarget);

    try {
      const response = await fetch(this.formTarget.action, {
        method: "PATCH",
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        console.error("errors: ", json);
      }
    } catch (error) {
      console.error("error: ", error);
    }

    if (nextView) {
      // 次の遷移先が設定されている場合は遷移する
      window.location.href = nextView;
    }
  }
}
