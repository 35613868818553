/**
 * フォームで選択されたファイルを data URL にする。
 * @param {Blob} file
 * @returns {Promise<string>}
 */
export function readFileAsDataURL(file) {
  return new Promise((resolve, _reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      resolve(event.target.result);
    };
    fileReader.readAsDataURL(file);
  });
}
