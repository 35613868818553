import { Controller } from "@hotwired/stimulus";

let idGenerator = 1;

/**
 * `<label>` とフォームコントロール (`<input>` 等) を紐づける。
 * `<template>` 等で一意な id があらかじめつけられない時に使う。
 *
 * ```html
 * <div data-controller="label-connector">
 *   <label data-label-connector-target="label"></label>
 *   <input data-label-connector-target="formControl">
 * </div>
 * ```
 */
export default class extends Controller {
  static targets = ["label", "formControl"];

  connect() {
    const prefix = "label-connector-";
    const id = `${prefix}${idGenerator++}`;

    /** @type {HTMLElement} */
    const formControl = this.formControlTarget;
    formControl.id = id;

    /** @type {HTMLLabelElement[]} */
    const labels = this.labelTargets;
    labels.forEach((label) => {
      label.htmlFor = id;
    });
  }
}
