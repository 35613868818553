import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="turbo-tab"
 * <%= link_to "any", any_path, data: { turbo_tab_target: "tab" }, class: "nav-link" %>
 */
export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.tabTargets.forEach((el) => {
      el.classList.remove("loading");

      el.dataset.turbo = true;
      el.addEventListener("turbo:click", this.#onClick);
    });
  }

  // UIにはロード待たずに即時反映したいけど click でスタイルをいじるとその後にキャッシュされてしまうので
  // 履歴を辿った場合に不整合が起きてしまう
  //   e.g.) tab-a, content-a が tab-b 行って戻ると tab-b, content-a になる
  // そのため click 時には一時的にスタイルをあてて connect で除去している
  //
  // @see: app/assets/stylesheets/renewal/partials/_nav.scss
  #onClick = (ev) => {
    ev.target.classList.add("loading");
  };
}
