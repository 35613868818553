import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="open-url-in-app" class="d-none"
 *
 * リンク:
 * link_to "ブラウザを開く", "url", data: { action: "open-url-in-app#open" }
 */
export default class extends Controller {
  connect() {
    document.addEventListener("MobilicoAppLoaded", () => {
      if (window.MobilicoApp?.openUrl) {
        this.element.classList.remove("d-none");
      }
    });
  }

  open(event) {
    const url = event.currentTarget.href;
    if (window.MobilicoApp?.openUrl) {
      event.preventDefault();
      window.MobilicoApp.openUrl(url);
    }
  }
}
