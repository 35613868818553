import { Controller } from "@hotwired/stimulus";

/** URL のハッシュ (`#` 以降の部分) をこの要素の `data-page-mode` 属性に入れる。
 *
 * data-controller="hash-page-mode"
 */
export default class extends Controller {
  connect() {
    window.addEventListener("hashchange", this.#onhashchange);
    this.#onhashchange();
  }

  disconnect() {
    window.removeEventListener("hashchange", this.#onhashchange);
  }

  #onhashchange = () => {
    this.element.dataset.pageMode = location.hash;
  };
}
