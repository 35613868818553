import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="drawer"
 *
 * require("jquery-drawer"); が必要。
 */
export default class extends Controller {
  connect() {
    $(this.element).drawer({
      iscroll: {
        // Configuring the iScroll
        // https://github.com/cubiq/iscroll#configuring-the-iscroll
        mouseWheel: true,
        preventDefault: false,
        scrollY: true,
      },
    });
  }
}
