import { Controller } from "@hotwired/stimulus";

// <a href="#comments" data-controller="scroll"
// targetSelector: "html" 以外の Element をスクロールする場合セレクタで指定
//   data-target-selector="#id"
// static values = { targetSelector: String }; を使おうと思ったけど v2 系ぽい
export default class extends Controller {
  connect() {
    if (this.element.dataset.targetSelector) {
      this.root = this.element.dataset.targetSelector;
    } else {
      this.root = "html";
    }

    this.targetElement = document.querySelector(
      this.element.getAttribute("href")
    );

    this.bind();
  }

  bind() {
    this.element.addEventListener("click", (ev) => {
      ev.preventDefault();
      this.scroll();
    });
  }

  scroll() {
    const x = this.targetElement.offsetLeft;
    const y = this.targetElement.offsetTop;
    // window.scroll behavior: smooth は safari で動作しないので jquery 500ms は感覚値
    // https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
    $(this.root).animate({ scrollLeft: x, scrollTop: y }, 500);
  }
}
