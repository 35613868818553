import { Controller } from "@hotwired/stimulus";

/**
 * about-dealer-maintenance
 */
export default class extends Controller {
  connect() {
    const content = $("#about-dealer-maintenance-modal .modal-content");
    const body = $("#about-dealer-maintenance-modal .modal-body");

    $("#about-dealer-maintenance-modal").on("show.bs.modal", (e) => {
      if (body.children().length > 0) return;
      content.hide();

      const url = e.relatedTarget.href;
      $.ajax({
        url: url,
        success: setContent,
      });
    });

    const setContent = (html) => {
      const res = $(html).find("#about-dealer-maintenance");
      body.append(res);
      content.fadeIn(200);
    };
  }
}
