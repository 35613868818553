import React from "react";
import { TriggerEvent } from "react-contexify";

interface OnContextMenuCallbackBody {
  posX: number;
  posY: number;
  trigger: TriggerEvent;
}

const longPressDuration = 610;
type OnContextMenuCallback = (callback: OnContextMenuCallbackBody) => void;

/**
 * refs: https://github.com/facebook/react/issues/17596#issuecomment-565524946
 */
export class ContextMenuHandler {
  private callback: OnContextMenuCallback;
  private longPressCountdown: number | undefined;

  constructor(callback: OnContextMenuCallback) {
    this.callback = callback;
    this.longPressCountdown = undefined;
  }

  onTouchStart = (event: React.TouchEvent): void => {
    const touch = event.touches[0];

    this.longPressCountdown = window.setTimeout(() => {
      const callbackBody: OnContextMenuCallbackBody = {
        posX: touch.screenX,
        posY: touch.screenY,
        trigger: event,
      };

      this.callback(callbackBody);
    }, longPressDuration);
  };

  onTouchMove = (): void => {
    clearTimeout(this.longPressCountdown);
  };

  onTouchCancel = (): void => {
    clearTimeout(this.longPressCountdown);
  };

  onTouchEnd = (): void => {
    clearTimeout(this.longPressCountdown);
  };

  onContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    clearTimeout(this.longPressCountdown);

    const domRect = event.currentTarget.getBoundingClientRect();

    const callbackBody: OnContextMenuCallbackBody = {
      posX: domRect.x,
      posY: domRect.y,
      trigger: event,
    };

    this.callback(callbackBody);
    event.preventDefault();
  };
}
