import { Controller } from "@hotwired/stimulus";

/** bootstrap の modal をすぐに表示する。
 *
 * 使用例:
 * ```
 * <div class="modal" tabindex="-1" role="dialog" data-controller="modal-show-on-connect">
 *  …
 * </div>
 * ```
 *
 * 遷移先の指定モーダルを指定する場合
 *
 *（遷移元）開きたいモーダルをmodalIdに
 * <% link_to mypage_listing_path(exhibited_car, modalId: 'SelectPublishingType') %>
 *
 *（遷移先）
 * <% if ['SelectPublishingType', 'unpublishConfirmationModal', 'carDeleteModal'].include?(params[:modalId]) %>
 *  <div data-controller="modal-show-on-connect" data-modal-show-on-connect-modal-id-value="<%= params[:modalId] %>">
 *  </div>
 * <% end %>
 *
 */

export default class extends Controller {
  static values = { modalId: String };

  connect() {
    const modalId = this.modalIdValue;

    setTimeout(() => {
      let modal;

      if (modalId) {
        modal = document.getElementById(modalId);
      } else {
        modal = this.element;
      }

      if (modal) window.BootstrapWrapper.showModal(modal);
    }, 250);
  }
}
