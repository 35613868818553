import { Controller } from "@hotwired/stimulus";

/**
 * user の notification 表示用。
 * data-controller="notifications-list"
 */
export default class extends Controller {
  connect() {
    $(".notification-ref").on("click", (ev) => {
      ev.preventDefault();
      $.ajax({
        method: "GET",
        url: ev.currentTarget.href,
      }).done((html) => {
        window.scrollTo(0, 0);
        $("#notifications").slideUp(166);
        const body = $("#notification");

        if (body.length > 0) {
          body.replaceWith(html);
        } else {
          $("#notifications").before(html);
        }
        $("#notification").hide().slideDown(166);
      });
    });
  }
}
