/* eslint-disable @typescript-eslint/no-explicit-any */
// https://github.com/zxing-js/library/blob/be06578976773f901e25b6711d321b72445da5ed/src/browser/BrowserCodeReader.ts#L146-L178
export async function getVideoInputDevices(): Promise<MediaDeviceInfo[]> {
  function hasNavigator(): boolean {
    return typeof navigator !== "undefined";
  }

  function isMediaDevicesSuported(): boolean {
    return hasNavigator() && !!navigator.mediaDevices;
  }
  function canEnumerateDevices(): boolean {
    return !!(
      isMediaDevicesSuported() && navigator.mediaDevices.enumerateDevices
    );
  }

  if (!hasNavigator()) {
    throw new Error("Can't enumerate devices, navigator is not present.");
  }

  if (!canEnumerateDevices()) {
    throw new Error("Can't enumerate devices, method not supported.");
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  const videoDevices: MediaDeviceInfo[] = [];

  for (const device of devices) {
    const kind =
      (device.kind as string) === "video" ? "videoinput" : device.kind;

    if (kind !== "videoinput") {
      continue;
    }

    const deviceId = device.deviceId || (device as any).id;
    const label = device.label || `Video device ${videoDevices.length + 1}`;
    const groupId = device.groupId;

    const videoDevice = { deviceId, label, kind, groupId } as MediaDeviceInfo;

    videoDevices.push(videoDevice);
  }

  return videoDevices;
}
