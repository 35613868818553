import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "formList"];

  connect() {
    this.template = this.templateTarget.cloneNode(true);
  }

  append(event) {
    if (this.data.get("imagesMax") <= this.formListTarget.children.length) {
      event.target.disabled = true;
      return;
    }

    this.formListTarget.appendChild(this.template.cloneNode(true));

    if (this.data.get("imagesMax") <= this.formListTarget.children.length) {
      event.target.disabled = true;
    }
  }
}
