import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTarget.disabled = true;
  }

  replace(event) {
    const [response, ,] = event.detail;
    this.element.outerHTML = response;
  }

  modified() {
    this.buttonTarget.disabled = false;
  }
}
