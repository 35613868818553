import { Controller } from "@hotwired/stimulus";

/**
 * `history.back()` で戻るやつ
 * link_to :back で戻ると通常の画面遷移になってしまってスクロール位置が先頭に戻ってしまう対策
 * 階層が深い 1→2→3 などの場合、3→2 の遷移があると 2→3 で戻ってしまうので注意
 * 3 にもこれを仕込んでおけば 3→2→1 で戻れる
 *
 * ### 使い方の基本
 *   - Default: <%= link_back_or_to root_path, class: "..." %>
 *   - StoreStaff: <%= link_back_or_to store_staff_root_path, class: "..." %>
 *   - Admin: <%= link_back_or_to admin_root_path, class: "..." %>
 *
 * ただし、data-controller の追加は link_back_or_to が同一サイト内の場合自動で追加してくれる
 * 自分で data-controller="history-back" を追加してもよい
 */
export default class extends Controller {
  connect() {
    this.#bind();
  }

  disconnect() {
    this.#clearTimeout();
  }

  #bind() {
    // ページ遷移した場合は timeout を止める。
    this.timeoutID = null;
    window.addEventListener("beforeunload", () => {
      // 止めなくてもページ遷移でキャンセルされてるっぽいけど、念の為。
      this.#clearTimeout();
    });

    window.addEventListener("hashchange", this.#onhashchange);

    this.element.addEventListener("click", (ev) => {
      ev.preventDefault();
      history.back();
      // すぐにページ遷移しなかったら、history.back で戻れるページが存在しないと見なす。
      // https://www.softel.co.jp/blogs/tech/archives/4322
      this.timeoutID = setTimeout(() => {
        location.href = this.element.href;
      }, 100);
    });
  }

  #clearTimeout() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = undefined;
    }
  }

  #onhashchange = () => {
    // history back で hash だけが変わる場合もある。
    this.#clearTimeout();
  };
}
