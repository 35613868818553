import React from "react";
import { toDateString } from "components/chatFunctions";
import { Message } from "@twilio/conversations";

export interface MessageUser {
  id: string;
  name: string;
  banned: boolean;
}

interface Props {
  message: Message;
  user: MessageUser;
  unread: boolean;
  storeStaffDomainPath: string;
  type: "give" | "receive";
}

function textFromMessage(message: Message, type: "give" | "receive"): string {
  switch (message.type) {
    case "text":
      return message.body;
    case "media":
      return type === "give"
        ? "ファイルを送信しました"
        : "ファイルが送信されました";
    default:
      return "";
  }
}

/**
 * ChatLastMessageList, ChatMessageList のリスト内に表示するメッセージ
 */
const MessageItem: React.FC<Props> = (props: Props) => {
  const { message, user, unread, storeStaffDomainPath, type } = props;

  return (
    <li className="list-group-item">
      <a
        className={"notification-ref list-group-item-link"}
        href={`${storeStaffDomainPath}/users/${user.id}/talkroom`}
        data-turbo={true}
      >
        {unread ? <span className="badge-notification"></span> : ""}
        <div className="pl-4 text-length">
          <div className="list-group-item-header">
            <strong>{user.banned ? "メンバーがいません" : user.name}</strong>
            <time className="d-block">
              {message?.dateCreated ? toDateString(message.dateCreated) : ""}
            </time>
          </div>
          <span className="text-gray">{textFromMessage(message, type)}</span>
        </div>
      </a>
    </li>
  );
};

export default MessageItem;
