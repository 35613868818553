import { Controller } from "@hotwired/stimulus";
require("jquery-jpostal-ja");

/**
 * ボタン押して反映のタイプ
 * https://github.com/ninton/jquery.jpostal.js/blob/master/sample_7.html
 * data: { controller: "postal-code" }
 */
export default class extends Controller {
  static targets = ["zipCode", "address"];

  connect() {
    const el = this.zipCodeTarget;

    el.addEventListener("change", () => {
      el.value = el.value.normalize("NFKC").replace(/\D/g, "");
    });

    const address = {};
    address["#" + this.addressTarget.id] = "%3%4%5";

    $(this.element).jpostal({
      url: {
        http: "/jpostal_json/",
        https: "/jpostal_json/",
      },
      click: "#input-address",
      postcode: ["#" + el.id],
      address: address,
    });
  }
}
