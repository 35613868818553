import { Controller } from "@hotwired/stimulus";
import { getFileSizeForHuman } from "../../shared/getFileSizeForHuman";

/** ファイルサイズを人間用に MB 等の単位で表示する。
 *
 * 使用例:
 * ```
 * <div data-controller="file-size-for-human" data-file-size="1024"></div>
 * ```
 */
export default class extends Controller {
  connect() {
    this.update();
  }

  // action でも更新可能。
  update() {
    if (!("fileSize" in this.element.dataset)) {
      return;
    }

    // parseInt は空文字列だと NaN になる。
    const fileSize = parseInt(this.element.dataset.fileSize, 10);
    if (isNaN(fileSize)) {
      return;
    }

    this.element.textContent = getFileSizeForHuman(fileSize);
  }
}
