import { Controller } from "@hotwired/stimulus";
import { stopEverything } from "@rails/ujs";

/**
 * ログアウトリンクのタップを、アプリからフックできるようにする。
 *
 * data-controller="destroy-user-session"
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (event) => {
      if (window.MobilicoApp?.signOutHook) {
        window.MobilicoApp.signOutHook();
        stopEverything(event);
      }
    });
  }
}
