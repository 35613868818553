import { Controller } from "@hotwired/stimulus";

/**
 * 点検・車検の予約申し込みフォーム
 *
 * data: {
 *   controller: "stall-reservation-request",
 *   action: "store-reservation-calendar:dateSelected->stall-reservation-request#updateDates store-reservation-calendar:connected->stall-reservation-request#calendarLoaded",
 *   stall_reservation_request_calendar_url: schedule_stall_reservation_request_path
 * }
 */
export default class extends Controller {
  static targets = [
    "maintenanceTypeValue",
    "maintenanceTypeContent",
    "maintenanceTypeModal",
    "optOilChange",
    "choice",
    "datetimeLabel",
    "submit",
    "calendarFrame",
  ];

  connect() {
    this.#syncDatetimeLabel();
  }

  /**
   * モーダルで選択した予約内容を反映する
   * @param {Event} event onClickイベント
   */
  changeMaintenanceType(event) {
    event.preventDefault();

    const data = event.currentTarget.dataset;
    if (this.maintenanceTypeValueTarget.value == data.value) {
      $(this.maintenanceTypeModalTarget).modal("hide");
      return;
    }
    // 条件が異なるので変更時には日時をクリア
    this.choiceTargets.forEach((x) => {
      x.value = "";
    });
    this.#updateSubmitStatus();
    this.#syncDatetimeLabel();
    this.#setCalendarUrl(data.value);
    this.maintenanceTypeValueTarget.value = data.value;
    this.maintenanceTypeContentTarget.textContent = data.text;

    // 予約内容がエンジンオイル交換の場合は追加整備のオイル交換を非表示にする
    if (data.value === this.optOilChangeTarget.dataset.value) {
      this.optOilChangeTarget.hidden = true;
      const optOilChangeCheck = this.optOilChangeTarget.querySelector(
        "[data-oil-change='value']"
      );
      optOilChangeCheck.checked = false;
    } else {
      this.optOilChangeTarget.hidden = false;
    }

    // 予約内容変更時にモーダルを閉じる
    $(this.maintenanceTypeModalTarget).modal("hide");
  }

  // 日時選択時のコールバック
  updateDates(ev) {
    this.choiceTargets.forEach((v, k) => {
      v.value = ev.detail.values[k];
    });
    this.#updateSubmitStatus();
    this.#syncDatetimeLabel();
  }

  /**
   * input datetime は iOS safari/webview で date-picker が表示されて現在日時で埋められてしまうため
   * フォーム自体は隠して別要素 `datetimeLabelTargets` に表示する
   * 日本語年月日で表示する必要がないのであれば input type=datetime-local に pointer-events: none だけで解決できる
   */
  #syncDatetimeLabel() {
    this.choiceTargets.forEach((el, k) => {
      if (isNaN(Date.parse(el.value))) {
        // パースできない場合は空欄
        this.datetimeLabelTargets[k].innerText = "";
      } else {
        this.datetimeLabelTargets[k].innerText = new Date(
          el.value
        ).toLocaleString("ja-JP", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      }
    });
  }

  // カレンダー表示時のコールバック
  calendarLoaded(ev) {
    const dates = this.choiceTargets.map((x) => x.value).filter((x) => x);

    if (dates.length == 3) {
      ev.detail.controller.setDates(dates);
    } else {
      ev.detail.controller.clear();
    }
  }

  #updateSubmitStatus() {
    this.submitTarget.disabled =
      this.choiceTargets.filter((x) => x.value).length != 3;
  }

  // 各点検区分のカレンダーURL
  #setCalendarUrl(maintenanceType) {
    if (!this.hasCalendarFrameTarget) return;
    this.calendarFrameTarget.src = `${this.data.get(
      "calendarUrl"
    )}?maintenance_type=${maintenanceType}`;
  }
}
