import { CarInfo, CarSpec } from "./types";
import { parseKatarui } from "./parseKatarui";
import { parseNumberPlate } from "./parseNumberPlate";
import { parseSyadaiBangou } from "./parseSyadaiBangou";

function optionalValue(value: string): string | null {
  if (
    value === "-" ||
    value === "*" ||
    value === "ー" ||
    value === "＊" ||
    value.match(/^-\x20*/)
  ) {
    return null;
  }

  return value;
}

export function parseKeiQR2(qr: string): CarInfo | null {
  // eslint-disable-next-line no-irregular-whitespace
  // "K/22/湘南　　５５５よ８８００/1/L150S-0014999/EF/1",
  const regex =
    /^K\/[^\\/]*?\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }
  const syaryouBangou = parseNumberPlate(match[1]);
  const numberPlateInfo = optionalValue(match[2]);
  const syadaiBangou = parseSyadaiBangou(match[3]);
  const gendoukiKatashiki = optionalValue(match[4]);
  const chouhyouType = match[5];
  return {
    syaryouBangou,
    numberPlateInfo,
    syadaiBangou,
    gendoukiKatashiki,
    chouhyouType,
  };
}

export function parseKeiQR3(qr: string): CarSpec | null {
  // "K/31/-  /116729999/219999/0212/UA-L150S/0051/-/-/0032/- /-  /-/-/-/-   /-    /999999",
  const regex =
    /^K\/[^\\/]*?\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }
  const syadaiBangouDakokuIchi = optionalValue(match[1]);
  const { katashikiSiteiBangou, ruibetsuKubunBangou } = parseKatarui(match[2]);
  const yuukouKikanManryoubi = !match[3]
    ? { year: null, month: null, day: null }
    : {
        year: "20" + match[3].substr(0, 2),
        month: match[3].substr(2, 2),
        day: match[3].substr(4, 2),
      };
  const shodoKensaNengetsu = {
    year: match[4].substr(0, 2) === "99" ? null : "20" + match[4].substr(0, 2),
    month: match[4].substr(2, 2) === "99" ? null : match[4].substr(2, 2),
  };
  const katashiki = match[5];
  const jikujuFF = optionalValue(match[6]);
  const jikujuFB = optionalValue(match[7]);
  const jikujuBF = optionalValue(match[8]);
  const jikujuBB = optionalValue(match[9]);
  const souonKisei = optionalValue(match[10]);
  const kinsetsuHaikiSouonKiseiNum = optionalValue(match[11]);
  const kudouHoushiki = optionalValue(match[12]);
  const opaciMeterSokuteiSya = optionalValue(match[13]);
  const noxPmSokuteiMode = optionalValue(match[14]);
  const noxNum = optionalValue(match[15]);
  const pmNum = optionalValue(match[16]);
  // 予備項目は普通自動車の項目には無い & 意味を持たない項目なので捨てる
  // const yobiKoumoku = match[17];
  // 普通自動車の車検証にあって軽自動車には無いので null
  const hoanKijunTekiyouYMD = null;
  const nenryouTypeCode = null;

  return {
    syadaiBangouDakokuIchi,
    katashikiSiteiBangou,
    ruibetsuKubunBangou,
    yuukouKikanManryoubi,
    shodoKensaNengetsu,
    katashiki,
    jikujuFF,
    jikujuFB,
    jikujuBF,
    jikujuBB,
    souonKisei,
    kinsetsuHaikiSouonKiseiNum,
    kudouHoushiki,
    opaciMeterSokuteiSya,
    noxPmSokuteiMode,
    noxNum,
    pmNum,
    hoanKijunTekiyouYMD,
    nenryouTypeCode,
  };
}

type KeiQR4 = {
  // 使用者氏名（QRコードが高水準文字に対応していないので低水準文字が使用される）
  shiyousyaShimei: string | null;
};

export function parseKeiQR4(qr: string): KeiQR4 | null {
  // eslint-disable-next-line no-irregular-whitespace
  // "K/51/田中　太郎",
  const regex = /^K\/5[^\\/]*?\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }
  const shiyousyaShimei = optionalValue(match[1]);
  return {
    shiyousyaShimei,
  };
}

type KeiQR5 = {
  // 使用者住所
  shiyousyaJuusyo: string | null;
};

export function parseKeiQR5(qr: string): KeiQR5 | null {
  // "K/61/神奈川県藤沢市〜〜１２３−１２３−３",
  const regex = /^K\/6[^\\/]*?\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }
  const shiyousyaJuusyo = optionalValue(match[1]);
  return {
    shiyousyaJuusyo,
  };
}

type KeiQR6 = {
  // 自動車の種別
  jidousyaSyubetsu: string;
  // 用途
  youto: string;
  // 自家用・事業用の別
  jikayouJigyouyouNoBetsu: string;
  // 車体の形状コード
  syataiKeijou: string | null;
  // 乗車定数１
  jousyaTeiin1: string | null;
  // 乗車定数２
  jousyaTeiin2: string | null;
  // 最大積載量１
  saidaiSaisekiRyou1: string | null;
  // 最大積載量２
  saidaiSaisekiRyou2: string | null;
  // 車両総重量１
  syaryouSouJuuryou1: string | null;
  // 車両総重量２
  syaryouSouJuuryou2: string | null;
  // 車名コード
  syamei: string;
  // 走行距離計・表示値
  soukoukyorikeiHyoujiNum: string;
  // 走行距離計・区分
  soukoukyorikeiKubun: string;
};

export function parseKeiQR6(qr: string): KeiQR6 | null {
  // "K/71/軽自動車/乗用/自家用/001/4/-/-/-/1050/-/151/77300/km"
  const regex =
    /^K\/[^\\/]*?\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)\/([^\\/]*?)$/;
  const match = qr.match(regex);
  if (!match) {
    return null;
  }
  const jidousyaSyubetsu = match[1];
  const youto = match[2];
  const jikayouJigyouyouNoBetsu = match[3];
  const syataiKeijou = optionalValue(match[4]);
  const jousyaTeiin1 = optionalValue(match[5]);
  const jousyaTeiin2 = optionalValue(match[6]);
  const saidaiSaisekiRyou1 = optionalValue(match[7]);
  const saidaiSaisekiRyou2 = optionalValue(match[8]);
  const syaryouSouJuuryou1 = optionalValue(match[9]);
  const syaryouSouJuuryou2 = optionalValue(match[10]);
  const syamei = match[11];
  const soukoukyorikeiHyoujiNum = match[12];
  const soukoukyorikeiKubun = match[13];

  return {
    jidousyaSyubetsu,
    youto,
    jikayouJigyouyouNoBetsu,
    syataiKeijou,
    jousyaTeiin1,
    jousyaTeiin2,
    saidaiSaisekiRyou1,
    saidaiSaisekiRyou2,
    syaryouSouJuuryou1,
    syaryouSouJuuryou2,
    syamei,
    soukoukyorikeiHyoujiNum,
    soukoukyorikeiKubun,
  };
}
