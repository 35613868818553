import { Controller } from "@hotwired/stimulus";

/** プログレスバーの幅を transition で表示する。
 * 0 から aria-valuenow で指定した % まで遷移する。
 *
 * data-controller="progress-bar-transition"
 * aria-valuenow="88"
 */
export default class extends Controller {
  connect() {
    const value = this.element.getAttribute("aria-valuenow");

    /** @type {CSSStyleDeclaration} */
    const style = this.element.style;
    style.transitionDuration = "415ms";
    style.transitionTimingFunction = "ease-out";
    style.width = 0;

    setTimeout(() => {
      style.width = `${value}%`;
    }, 0);
  }
}
