import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

/**
 * css background-image を lazy-loading するやつ
 * class="bg-lazy" は初めから振っておかないとリクエスト走る
 *
 * class="bg-lazy" data-controller="bg-lazy"
 */
export default class extends Controller {
  connect() {
    this.unobserve = useIntersection(this)[1];
  }

  appear() {
    this.element.classList.add("bg-fade");
    this.element.classList.remove("bg-lazy");
    this.unobserve();
  }
}
