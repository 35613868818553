import { Controller } from "@hotwired/stimulus";

/** チェックボックスのオンオフでフォームフィールドの有効無効を切り替える。
 *
 * action の `form-field-disabler#apply` は change event で自動で呼ばれる。
 * 何度呼んでも OK で、他の controller の action よりも早く呼びたい場合は、
 * `data-action="form-field-disabler#apply other-controller#action"` のようにする。
 *
 * 使用例:
 * ```
 *  <div data-controller="form-field-disabler">
 *    <input type="checkbox" data-form-field-disabler-target="checkbox">
 *    <input type="text" data-form-field-disabler-target="enableOnCheck">
 *    <input type="text" data-form-field-disabler-target="disableOnCheck">
 *    <input type="text" data-form-field-disabler-target="showOnCheck">
 *    <input type="text" data-form-field-disabler-target="hideOnCheck">
 *  </div>
 * ```
 */
export default class extends Controller {
  static targets = [
    "checkbox",
    "enableOnCheck",
    "disableOnCheck",
    "showOnCheck",
    "hideOnCheck",
  ];

  connect() {
    this.checkboxTarget.addEventListener("change", this.apply);
    this.apply();
  }

  disconnect() {
    this.checkboxTarget.removeEventListener("change", this.apply);
  }

  apply = () => {
    const checkbox = this.checkboxTarget;

    this.enableOnCheckTargets.forEach((field) => {
      field.disabled = !checkbox.checked;
    });
    this.disableOnCheckTargets.forEach((field) => {
      field.disabled = checkbox.checked;
    });
    this.showOnCheckTargets.forEach((field) => {
      field.style.display = !checkbox.checked ? "none" : "block";
    });
    this.hideOnCheckTargets.forEach((field) => {
      field.style.display = checkbox.checked ? "none" : "block";
    });
  };
}
