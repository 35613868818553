import { Controller } from "@hotwired/stimulus";

/**
 * submit 時にボタンを無効化する
 * disable_with が効かない時用
 *
 * ```
 * <form data-controller="submit-process-disable-with" data-action="submit->submit-process-disable-with#disableButton">
 *   <button type="buttom" data-submit-process-with-disable="button">...</button>
 * </form>
 * ```
 */
export default class extends Controller {
  static targets = ["button"];

  disableButton() {
    this.buttonTarget.disabled = true;
  }
}
