import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  forceUpperAlphaNumericAndHyphen(ev) {
    let v = ev.currentTarget.value;
    v = this.normalize(v);
    v = v.toUpperCase();
    v = this.filterByAlphaNumericAndHyphen(v);
    ev.currentTarget.value = v;
  }

  forceUpperAlphaNumeric(ev) {
    let v = ev.currentTarget.value;
    v = this.normalize(v);
    v = v.toUpperCase();
    v = this.filterByAlphaNumeric(v);
    ev.currentTarget.value = v;
  }

  forceNumeric(ev) {
    let v = ev.currentTarget.value;
    v = this.normalize(v);
    v = this.filterByNumeric(v);
    ev.currentTarget.value = v;
  }

  forceNormalize(ev) {
    let v = ev.currentTarget.value;
    v = this.normalize(v);
    ev.currentTarget.value = v;
  }

  normalize(s) {
    return s.normalize("NFKC");
  }

  filterByAlphaNumeric(s) {
    return s.replace(/[^0-9A-Za-z]+/g, "");
  }

  filterByAlphaNumericAndHyphen(s) {
    return s.replace(/[^0-9A-Za-z-]+/g, "");
  }

  filterByNumeric(s) {
    return s.replace(/[^\d]+/g, "");
  }
}
