import { Controller } from "@hotwired/stimulus";
import Likes from "../../shared/exhibited_car_likes";

/**
 * exhibited-car-like
 */
export default class extends Controller {
  connect() {
    const btn = $(this.element);
    btn.click((event) => {
      event.preventDefault();
      this.onclick();
    });
    this.likes = new Likes();
  }

  onclick() {
    const btn = $(this.element);
    const count = parseInt(btn.text());
    const endpoint = this.data.get("endpoint");

    if (btn.hasClass("on")) {
      this.likes.delete(endpoint, () => {
        const n = count - 1;
        btn.text(n);
        btn.removeClass("on");
      });
    } else {
      this.likes.create(endpoint, () => {
        const n = count + 1;
        btn.text(n);
        btn.addClass("on");
      });
    }
  }
}
