import { Controller } from "@hotwired/stimulus";

// 選択した店舗に応じてスタッフの表示を切り替える。
// option タグは display: none を指定しても消すことができないため
// スタッフの select タグは店舗の数だけ作成しておき、select タグ毎に
// 表示／非表示を切り替えるよう実装してある。
// <div class="container" data-controller="store-select">
//   店舗選択
//   <select data-store-select-target="store" data-action="store-select#update">
//     <option value="100">あざみ野店</option>
//   </select>
//   スタッフ選択
//   <select data-store-select-target="staff" data-store-id="1">...</select>
//   <select data-store-select-target="staff" data-store-id="2">...</select>
// </div>
export default class extends Controller {
  static targets = ["store", "staff"];

  connect() {
    this.update();
  }

  update() {
    this.staffTargets.forEach((staff) => {
      staff.dataset.storeId === this.storeTarget.value
        ? $(staff).prop("disabled", false).show()
        : $(staff).prop("disabled", true).hide();
    });
  }
}
