import { Controller } from "@hotwired/stimulus";

export default class ReactController extends Controller {
  connect() {
    // Turbo Drive の preview 時には mount しない。
    // https://turbo.hotwired.dev/handbook/building#detecting-when-a-preview-is-visible
    this.preview = document.documentElement.hasAttribute("data-turbo-preview");

    if (!this.preview) {
      // 子供から検索されるので、親を渡す。
      // https://github.com/reactjs/react-rails/blob/4cbf13d44b7b67335124bef91820652e2b0bb930/react_ujs/index.js#L35-L61
      window.ReactRailsUJS.mountComponents(this.element.parentElement);
    }
  }

  disconnect() {
    if (!this.preview) {
      window.ReactRailsUJS.unmountComponents(this.element.parentElement);
    }
  }
}
