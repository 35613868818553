import { Controller } from "@hotwired/stimulus";

/** bootstrap の toast をすぐに表示する。
 *
 * 使用例:
 * ```
 * <div class="toast" data-controller="flash-show-on-connect">
 *  …
 * </div>
 * ```
 */
export default class extends Controller {
  connect() {
    $(this.element).toast({ delay: 10000 }).toast("show");
  }
}
