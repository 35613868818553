import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

/** change イベント等で remote な form を送信するやつ。
 *
 * 使用例:
 * ```
 * <form data-remote="true" data-controller="form-submit">
 *   <input data-action="form-submit#submit" type="checkbox">
 * </div>
 * ```
 */
export default class extends Controller {
  submit(event) {
    const form = event.target.form;

    if (form) {
      // `form.requestSubmit()` が使えれば remote じゃない form でも送信できる。
      // turbo に polyfill が入っているけど user では使っていないので requestSubmit は使えない。
      Rails.fire(form, "submit");
    }
  }
}
