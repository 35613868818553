import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "type",
    "audioTypeSection",
    "carNaviTypeSection",
    "naviKitSection",
    "carNaviType",
    "naviKit",
  ];

  static values = {
    navigation: String,
  };

  connect() {
    this.#updateDisplayForNavigationType();
  }

  changeNavigationSection(event) {
    // ディスプレイオーディオをなしに選択している場合:カーナビ,オーディオを表示、ナビキットを非表示
    if (event.target.value == "navigation_group") {
      this.#enableNavigationGroupUI();
    } else {
      // ディスプレイオーディオをありに選択している場合:ナビキットを表示、カーナビ,オーディオを非表示
      this.#enableDisplayAudioGroupUI();
    }
  }

  #updateDisplayForNavigationType() {
    const isNavigationGroup = [
      "none",
      "third_party",
      "maker_option",
      "store_option",
      "genuine",
      "unknown",
    ].includes(this.navigationValue);
    const isDisplayAudioGroup = [
      "display_audio",
      "display_audio_with_navi",
    ].includes(this.navigationValue);
    const allUnchecked = this.typeTargets.every((radio) => !radio.checked);

    // 既に一度登録されている場合にその選択肢を表示する
    if (isNavigationGroup) {
      this.#enableNavigationGroupUI();

      this.typeTargets.forEach((radio) => {
        if (radio.value == "navigation_group") {
          // 該当するラジオボタンを選択状態にする
          radio.checked = true;
        }
      });
      this.carNaviTypeTarget.value = this.navigationValue;
    } else if (isDisplayAudioGroup) {
      this.#enableDisplayAudioGroupUI();
      this.typeTargets.forEach((radio) => {
        if (radio.value == "display_audio_group") {
          // 該当するラジオボタンを選択状態にする
          radio.checked = true;
        }
      });
    }
    // まだ登録されていない場合は最初のラジオボタンを選択状態にする
    else if (allUnchecked) {
      this.typeTargets.forEach((radio, index) => {
        if (index === 0) {
          radio.checked = true;
        }
      });
    }
  }

  // ナビゲーション用
  #enableNavigationGroupUI() {
    this.carNaviTypeTarget.disabled = false;
    this.carNaviTypeSectionTarget.classList.remove("d-none");
    this.audioTypeSectionTarget.classList.remove("d-none");

    this.naviKitSectionTarget.classList.add("d-none");
    this.naviKitTargets.forEach((radio) => {
      radio.disabled = true;
    });
  }

  // ディスプレイオーディオ用
  #enableDisplayAudioGroupUI() {
    // naviKitTargets内のいずれかのラジオがチェックされているか確認
    const isAnyChecked = this.naviKitTargets.some((radio) => radio.checked);
    this.naviKitTargets.forEach((radio, index) => {
      radio.disabled = false;
      // もしnaviKitTargets内のどのラジオボタンも選択されていなければ、最初のラジオボタンを選択状態にする
      if (!isAnyChecked && index === 0) {
        radio.checked = true;
      }
    });
    this.naviKitSectionTarget.classList.remove("d-none");

    this.audioTypeSectionTarget.classList.add("d-none");
    this.carNaviTypeSectionTarget.classList.add("d-none");
    this.carNaviTypeTarget.disabled = true;
  }
}
