import React, { useEffect, useRef } from "react";

interface Props {
  id: string;
  className?: string;
}

/**
 * id で指定された `<template>` を `<div>` 内に clone して react 内に埋め込む。
 */
const TemplateClone: React.VFC<Props> = (props: Props) => {
  const { id, className } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = ref.current;
    if (!div) {
      return;
    }

    const template = document.getElementById(id);
    if (!(template instanceof HTMLTemplateElement)) {
      return;
    }

    const clone = template.content.cloneNode(true);

    // replaceChildren は tsc で error TS2339 が出た。
    while (div.childNodes.length > 0) {
      div.childNodes[0].remove();
    }
    div.append(clone);
  }, [id]);

  return <div ref={ref} className={className}></div>;
};

export default TemplateClone;
